export const pagePaths = {
  base: process.env.REACT_APP_LOCAL
    ? 'http://localhost:3000/#'
    : 'https://admin.pkbroz.com/#',
  faview_admin: 'https://faview.ai/admin/:id',
  faview_vr: 'https://faview.ai/view/:id',
  faview_subdomain: 'https://faview.ai/:sub',

  auth: {
    signIn: '/signin',
    signUp: '/signup',
  },
  user: {
    adminList: '/admin',
    list: '/user',
    detail: '/user/:id',
    dashboardData: '/dashboard-data/',
    dashboardApp: '/dashboard-app/',
  },
  curation: {
    list: '/curation',
    detail: '/curation/:id',
  },
  space: {
    list: '/space',
    detail: '/space/:id',
  },
  keyword: {
    list: '/keyword',
    customList: '/custom-keyword',
    customDetail: '/custom-keyword/:id',
    filterList: '/filter',
  },
  notification: {
    list: '/notification',
    create: '/notification/create',
    detail: '/notification/:id',
  },
  event: {
    list: '/event',
    create: '/event/create',
    detail: '/event/:id',
  },
  document: {
    list: '/document',
    create: '/document/create',
    detail: '/document/:id',
  },
  feedback: {
    list: '/feedback',
    detail: '/feedback/:id',
  },
  push: {
    list: '/push-notification',
    detail: '/push-notification/:id',
  },
  store: {
    managerList: '/manager',
    managerDetail: '/manager/:id',
    productList: '/product',
    productDetail: '/product/:id',
    editRequestList: '/edit-request',
    editRequestDetail: '/edit-request/:id',
    orderList: '/order-list',
  },
  managing: {
    link: '/link-manager',
    version: '/version',
  },
  etc: {
    threejs: '/test-three-js',
    test: '/test',
  },
  // faview-portal
  faviewRequestList: '/faview-request',
};

/* API 경로 룰 (필독) - 안지키면 매우 쫄겁니다
  TODO(all): 경로의 언더바 다 삭제할것
  1. get api call의 경로명은 무조건 get으로 시작한다.
  2. api 경로명은 기능동사+메인모델+서브모델 혹은 부연명사(publish 등)
  3. url에 부득이하게 두 단어가 들어갈 경우 middle bar '-'를 사용한다.
     언더바가 보일시 서버 개발팀에 말해서 고칠수 있도록 전달한다.
  4. 릴레이션 관련 api 네이밍시 메인이 되는 모델이 앞에, 관련 서브모델을 뒤에 위치시킨다.
     ex) 공간의 키워드를 가져오는 기능 -> getSpaceKeyword
  5. api path에 파라미터가 존재하는 경우 주석으로 꼭 명시한다.
  6. api path 끝에는 무조건 슬래시 '/' 를 붙여둔다. 
     통일성 + 파라미터가 필요하게 수정될수 있으므로 미리미리 붙여두는 습관을 들입시다.
*/
export const apiPaths = {
  // default path
  awsBaseUrl: 'https://apiadmin.pkbroz.com/',
  devBaseUrl: 'http://localhost:5030/',
  // auth
  loginAdmin: 'auth/admin/sign-in/',
  checkAdmin: 'auth/admin/check-user/',
  logoutAdmin: 'auth/admin/sign-out/',
  registerAdmin: 'auth/admin/register/',
  editAdmin: 'auth/admin/edit/',

  // < DASHBOARD > /////////////////////////////////////////////////////////////////
  getDashboardData: 'admin/dashboard-data/data',
  getClosedData: 'admin/dashboard-data/find-closed',
  getSpaceNum: 'admin/dashboard-data/space-num',
  getErrorSpaceTitleDuplicate: 'admin/dashboard-data/space/title-duplicate',
  getErrorSpaceMenuLineBreak: 'admin/dashboard-data/space/menu/no-line-break',
  getErrorSpaceNoKeyword: 'admin/dashboard-data/space/no-keyword',
  getErrorSpaceNoTime: 'admin/dashboard-data/space/no-time',
  getErrorSpaceNoMenu: 'admin/dashboard-data/space/no-menu',

  getDashboardView: 'admin/dashboard-app/user-view',

  // < USER > /////////////////////////////////////////////////////////////////
  getUserAll: 'admin/user/',
  getUserSingle: 'admin/user/retrieve-info/',
  // < USER - Keyword >
  getUserKeyword: 'admin/user/keyword/user/',
  getUserWeight: 'admin/user/keyword/weight/',
  editUserWeight: 'admin/user/keyword/weight/edit/',
  getUserViewLike: 'admin/user/keyword/views_likes/', //파라미터로 id,
  getUserRecommend: 'admin/user/recommend/', //파라미터로 id,

  // < CURATION > //////////////////////////////////////////////////////////////////////////////
  getCurationAll: 'admin/curation/',
  getCurationSingle: 'admin/curation/',
  getCurationAllForPush: 'admin/curation/simplify',
  registerCuration: 'admin/curation/register/',
  editCuration: 'admin/curation/edit/',
  setCurationOrder: 'admin/curation/set-order',
  deleteCuration: 'admin/curation/delete/',
  editCurationPublish: 'admin/curation/edit/publish/',
  editCurationUploadDate: 'admin/curation/edit/upload-date',
  editCurationPublishInsta: 'admin/curation/edit/publish-insta',
  updateCurationJson: 'admin/curation/update-json',
  // < CURATION - Curated >
  getSpaceCurated: 'admin/curation/curated/', // param : Curation id
  attachSpaceCurated: 'admin/curation/curated/attach/',
  detachSpaceCurated: 'admin/curation/curated/detach/',
  setSpaceCuratedOrder: 'admin/curation/curated/set-order',
  // < CURATION - Keyword >
  getCurationKeyword: 'admin/curation/keyword/', // param : Curation id
  addCurationKeyword: 'admin/curation/keyword/add-keyword',
  removeCurationKeyword: 'admin/curation/keyword/remove-keyword',
  updateVibeCurationKeyword: 'admin/curation/keyword/update-vibe/',
  // < CURATION - Image >
  uploadCurationImage: 'admin/curation/img/upload/',

  // < SPACE > /////////////////////////////////////////////////////////////////////////////////
  getSpaceAll: 'admin/space/',
  getSpaceSingle: 'admin/space/', // param : Space id
  registerSpace: 'admin/space/register/',
  editSpace: 'admin/space/edit/',
  editPaidSpace: 'admin/space/edit/paid/',
  switchSpacePublish: 'admin/space/edit/publish/',
  editSpacePublishDate: 'admin/space/edit/publish-date',
  deleteSpace: 'admin/space/delete/',
  updateSpaceJson: 'admin/space/update-json',
  extendExpired: 'admin/space/extend-expired',

  // < SPACE - Filter >
  getSpaceFilter: 'admin/space/filter/', // param : Space id
  addSpaceFilter: 'admin/space/filter/add-filter',
  removeSpaceFilter: 'admin/space/filter/remove-filter',
  updateSpaceFilter: 'admin/space/filter/update/',
  // < SPACE - Keyword >
  getSpaceKeyword: 'admin/space/keyword/', // param : Space id
  addSpaceKeyword: 'admin/space/keyword/add-keyword',
  removeSpaceKeyword: 'admin/space/keyword/remove-keyword',
  updateSpaceKeyword: 'admin/space/keyword/update/',
  updateVibeSpaceKeyword: 'admin/space/keyword/update-vibe/',
  // < SPACE - Image >
  getSpaceImage: 'admin/space/img/', // param : Space id
  uploadSpaceImage: 'admin/space/img/upload/',
  uploadSpaceImageForward: 'admin/space/img/upload-forward/',
  deleteSpaceImage: 'admin/space/img/delete/',
  deleteSpaceAllImage: 'admin/space/img/reset/',
  getSpaceVideo: 'admin/space/video/', // param : Space id
  uploadSpaceVideo: 'admin/space/video/upload-video',
  deleteSpaceAllVideo: 'admin/space/video/reset',
  // < SPACE - Address >
  searchAddressByName: 'admin/space/address/search-address-by-place/',
  convertAddressWithNaver: 'admin/space/address/convert-with-naver-api/',
  convertAddressWithGoogle: 'admin/space/address/convert-with-google-api/',

  // < PANORAMA > /////////////////////////////////////////////////////////////////////////////////
  getPanorama: 'admin/panorama/space/', // 파라미터로 id
  registerPanorama: 'admin/panorama/register/',
  uploadPanoramaImage: 'admin/panorama/img/upload/',
  deletePanoramaImage: 'admin/panorama/delete/', // 파라미터로 id
  deletePanoramaImageAll: 'admin/panorama/delete-all/', // 파라미터로 id
  refreshPanoramaImage: 'admin/panorama/delete-empty/', // 파라미터로 id
  editPanorama: 'admin/panorama/edit/',
  editPanoramaMediaInfo: 'admin/panorama/edit-media',
  uploadPanonamaMediaImage: 'admin/panorama/img/upload-media-image',
  deletePanoramaMediaImage: 'admin/panorama/img/delete-media-image',
  uploadPanonamaMediaVideo: 'admin/panorama/img/upload-media-video',
  deletePanoramaMediaVideo: 'admin/panorama/img/delete-media-video',
  editPanoramaRoomNameAll: 'admin/panorama/edit-roomname-all/',
  attachPanoramaRelationBlock: 'admin/panorama/associate/spot/attach-block/',
  attachPanoramaRelationConnect:
    'admin/panorama/associate/spot/attach-connect/',
  attachPanoramaRelationExternal:
    'admin/panorama/associate/spot/attach-external',
  attachPanoramaRelationMedia: 'admin/panorama/associate/spot/attach-media',
  detachPanoramaRelation: 'admin/panorama/associate/spot/detach-relation/',
  detachPanoramaRelationExternal:
    'admin/panorama/associate/spot/detach-external/',
  loadPanoramaFromDefaultMode: 'admin/panorama/load-from-default/',
  changePanoramaMode: 'admin/panorama/change-mode/',
  formatPanoramaMode: 'admin/panorama/format-mode/',

  // keyword
  getKeywordAll: 'admin/keyword/',
  registerKeyword: 'admin/keyword/register/',
  deleteKeyword: 'admin/keyword/delete/',
  editKeyword: 'admin/keyword/edit',
  editCustomKeyword: 'admin/keyword/edit-custom',
  // filter
  getFilterAll: 'admin/filter/',
  registerFilter: 'admin/filter/register/',
  deleteFilter: 'admin/filter/delete/',
  // category
  // getCategoryAll: 'category/',
  // notification
  registerNotification: 'admin/notification/register/',
  getNotificationAll: 'admin/notification/',
  getNotificationSingle: 'admin/notification/', // 파라미터로 id
  deleteNotification: 'admin/notification/delete/',
  editNotification: 'admin/notification/edit/',
  // documentation / 공지사항
  docsBase: 'admin/docs/',
  deleteDocs: 'admin/docs/delete/',
  editDocs: 'admin/docs/edit/',
  registerDocs: 'admin/docs/register/',
  // feedback
  feedbackBase: 'admin/feedback/',
  registerFeedback: 'admin/feedback/',
  deleteFeedback: 'admin/feedback/delete/',
  getStoreEditRequest: 'admin/feedback/edit-request/',
  // event
  eventBase: 'admin/event/',
  registerEvent: 'admin/event/register/',
  editEvent: 'admin/event/edit/',
  editEventAnnounced: 'admin/event/edit-announced',
  deleteEvent: 'admin/event/delete/',
  preprocessEventAnnounce: 'admin/event/all-lose/',
  pickEventWinner: 'admin/event/pick-winner/',
  getEventImage: 'admin/event/img/',
  uploadEventImage: 'admin/event/img/upload/',
  deleteEventImage: 'admin/event/img/delete/',

  // category
  getCategoryAll: 'admin/category/',
  // pushNotification
  getPushAll: 'admin/push/',
  getPushSingle: 'admin/push/', // 파라미터로 id,
  editPush: 'admin/push/edit',
  registerPush: 'admin/push/create',

  // link
  getLink: 'admin/link',
  editLink: 'admin/link/edit',

  // version
  versionBase: 'admin/version/',
  registerVersion: 'admin/version/register/',
  editVersion: 'admin/version/edit/',
  deleteVersion: 'admin/version/delete/',

  // store manager
  getManagerAll: 'admin/store/manager/',
  getManagerSingle: 'admin/store/manager/:id',
  editManager: 'admin/store/manager/edit',
  editManagerStatus: 'admin/store/manager/edit/status',
  getProductAll: 'admin/store/product/',
  getProductSingle: 'admin/store/product/:id',
  editProduct: 'admin/store/product/edit',
  editProductPublishAd: 'admin/store/product/edit/publish_ad',
  getProductImage: 'admin/store/product/img/',
  uploadProductImage: 'admin/store/product/img/upload',
  deleteProductImage: 'admin/store/product/img/delete',
  getOrderHistory: 'admin/store/order/',

  // faview request
  getFaviewRequestAll: 'admin/faview-request/',
  getFaviewRequestSingle: 'admin/faview-request/:id',
  editFaviewRequest: 'admin/faview-request/edit',
};
