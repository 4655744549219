import { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { spaceAPI } from 'api';
import { panoramaAPI } from 'modules/api';
import { icon_dot, icon_disabled } from 'assets/img/icon';
import useInputState from 'modules/customHooks/useInputState';
import { TailSpin } from 'react-loader-spinner';
import { palette } from 'modules/defines/styles';
import { panorama_values } from 'modules/defines/values';
import { pagePaths } from 'modules/defines/paths';
import { useQuery } from '@tanstack/react-query';
import { icon_imgadd, icon_trashbin, icon_plus } from 'assets/img/icon';

const PanoramaModal = ({ spaceId, closeCurrentModal, defaultFloor }) => {
  const [show, setShow] = useState(false);
  const handleClick = useCallback(() => (show ? setShow(false) : null), [show]);
  const handleKeyDown = (e) => {
    if (e.code === 'Escape') {
      closeCurrentModal();
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClick);
    document.addEventListener('contextmenu', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
      document.removeEventListener('contextmenu', handleClick);
    };
  });
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  // API : Load PanoramaData
  const [dataLoading, setDataLoading] = useState(true);

  const fetchList = (spaceId) => {
    const { call, cancel } = panoramaAPI.getPanorama(spaceId);
    call.cancel = cancel;
    return call;
  };

  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ['panorama', spaceId],
    queryFn: () => fetchList(spaceId),
    refetchOnWindowFocus: true,
    refetchInterval: false,
    staleTime: 5 * 1000 * 60,
  });
  useEffect(() => {
    if (!isLoading) {
      setDataLoading(false);
    }
  }, [isLoading]);

  // Global Value : Current Floor
  const [floorDropdownData, setFloorDropdownData] = useState(
    panorama_values.floor
  );

  const {
    state: mode,
    setState: setMode,
    onChange: modeOnChange,
  } = useInputState('default');

  const {
    state: floor,
    setState: setFloor,
    onChange: floorOnChange,
  } = useInputState(1);
  const [isSubFloor, setIsSubFloor] = useState(false);
  // Global Value : Current Floor List
  const [floorList, setFloorList] = useState([]);
  // Global Value : Current Grid Size
  const [sizeDropdownData] = useState([3, 5, 7, 9, 11, 13, 21]);
  const [showSubPano, setShowSubPano] = useState(false);
  // Global Value : Panorama Images
  const [panoramaImage, setPanoramaImage] = useState([]);
  // Position Value : category
  const [categoryDropdownData] = useState(panorama_values.category);
  const [subCategoryDropdownData] = useState(panorama_values.subcategory);
  const [typeDropdownData] = useState(panorama_values.type);
  // 파노라마 정보 수정 패널 세팅
  const [showDataEditPanel, setShowDataEditPanel] = useState(false);
  // 우클릭 삭제를 위한 세팅
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  const [coordinateContext, setCoordinateContext] = useState({ x: 0, y: 0 });
  const [idSelectedContext, setIdSelectedContext] = useState(0);
  const [imageLinkContext, setImageLinkContext] = useState('');
  const [idSelected, setIdSelected] = useState(0);
  const [coordinate, setCoordinate] = useState({ x: 0, y: 0 });
  const [spotRelationBlock, setSpotRelationBlock] = useState([]);
  const [spotRelationConnect, setSpotRelationConnect] = useState([]);
  const [spotExternalConnect, setSpotExternalConnect] = useState([]);
  const [spotMediaConnect, setSpotMediaConnect] = useState([]);
  const [isHoveringHelp, setIsHoveringHelp] = useState(-1);
  const [mediaImage, setMediaImage] = useState();
  const [mediaVideo, setMediaVideo] = useState();
  const [videoUploadLoading, setVideoUploadLoading] = useState(false);
  const {
    state: size,
    setState: setSize,
    onChange: sizeOnChange,
  } = useInputState(7);
  const {
    state: roomCategory,
    setState: setRoomCategory,
    onChange: roomCategoryOnChange,
  } = useInputState('');
  const {
    state: type,
    setState: setType,
    onChange: typeOnChange,
  } = useInputState('');
  // Position Value : room_name
  const {
    state: roomName,
    setState: setRoomName,
    onChange: roomNameOnChange,
  } = useInputState('');
  const {
    state: title,
    setState: setTitle,
    onChange: titleOnChange,
  } = useInputState('');
  const {
    state: mediaSize,
    setState: setMediaSize,
    onChange: mediaSizeOnChange,
  } = useInputState('');
  const {
    state: desc,
    setState: setDesc,
    onChange: descOnChange,
  } = useInputState('');
  const {
    state: globalRoomName,
    setState: setGlobalRoomName,
    onChange: globalRoomNameOnChange,
  } = useInputState('');
  // Position Value (Relation) : Block
  const {
    state: relationBlockAttachValue,
    setState: setRelationBlockAttachValue,
    onChange: relationBlockAttachValueOnChange,
  } = useInputState('');
  // Position Value (Relation) : Connect
  const {
    state: relationConnectValDir1,
    setState: setRelationConnectValDir1,
    onChange: relationConnectValDir1OnChange,
  } = useInputState('');
  const {
    state: relationConnectValDir2,
    setState: setRelationConnectValDir2,
    onChange: relationConnectValDir2OnChange,
  } = useInputState('');
  const {
    state: relationConnectAttachValue,
    setState: setRelationConnectAttachValue,
    onChange: relationConnectAttachValueOnChange,
  } = useInputState('');

  const {
    state: relationExternalValDir1,
    setState: setRelationExternalValDir1,
    onChange: relationExternalValDir1OnChange,
  } = useInputState('');
  const {
    state: relationExternalValDir2,
    setState: setRelationExternalValDir2,
    onChange: relationExternalValDir2OnChange,
  } = useInputState('');
  const {
    state: relationExternalAttachValue,
    setState: setRelationExternalAttachValue,
    onChange: relationExternalAttachValueOnChange,
  } = useInputState('');
  const {
    state: relationExternalSpaceIdValue,
    setState: setRelationExternalSpaceIdValue,
    onChange: relationExternalSpaceIdValueOnChange,
  } = useInputState('');
  const {
    state: latitude,
    setState: setLatitude,
    onChange: latitudeOnChange,
  } = useInputState('');
  const {
    state: longitude,
    setState: setLongitude,
    onChange: longitudeOnChange,
  } = useInputState('');
  const {
    state: relationPanoVal,
    setState: setRelationPanoVal,
    onChange: relationPanoValOnChange,
  } = useInputState('');

  // Panorama Data Initialize
  useEffect(() => {
    let checkImage = [];
    data?.map((item) => {
      if (
        item.floor == floor &&
        item.mode[panorama_values.mode.indexOf(mode)] == '1'
      ) {
        checkImage.push(item);
      }
    });
    setPanoramaImage(checkImage);
    setFloorList([]);
    if (
      floorList.length === 0 &&
      defaultFloor !== null &&
      defaultFloor.length !== 0
    ) {
      setFloor(defaultFloor);
    }

    // 층수 구하기
    data?.forEach((item) => {
      setFloorList((prevState) => {
        if (!prevState.includes(item.floor)) {
          return [...prevState, item.floor];
        } else {
          return prevState;
        }
      });
    });
    if (Number.isInteger(Number.parseFloat(floor))) {
      setIsSubFloor(false);
    } else {
      setIsSubFloor(true);
      const roomNameForSubFloor = checkImage[0]?.room_name;
      if (roomNameForSubFloor === undefined || roomNameForSubFloor === null) {
        setGlobalRoomName('');
      } else {
        setGlobalRoomName(roomNameForSubFloor);
      }
    }
  }, [data, floor, mode]);

  useEffect(() => {
    setRoomName(globalRoomName);
  }, [globalRoomName]);

  useEffect(() => {
    for (var floor of floorList) {
      const subFloor = Number.parseFloat(Number.parseFloat(floor).toFixed(1));
      if (!floorDropdownData.includes(subFloor)) {
        setFloorDropdownData((prev) => [...prev, subFloor]);
      }
    }
  }, [floorList]);

  const clickPanoramaItem = (e, panorama) => {
    e.preventDefault();
    setShowDataEditPanel(true);
    setIdSelected(panorama.id);
    setCoordinate({
      x: panorama.x,
      y: panorama.y,
    });
    setRoomCategory(panorama.category ?? '');
    setType(panorama.type ?? '');
    setTitle(panorama.title ?? '');
    setDesc(panorama.description ?? '');
    setMediaSize(panorama.size ?? '');
    setMediaImage(panorama.image ?? '');
    setMediaVideo(panorama.video ?? '');
    setRoomName(panorama.room_name ?? '');
    setSpotRelationBlock(
      panorama.panoramaself1
        .concat(panorama.panoramaself2)
        .filter((item) => item.PanoramaSelf.type === 'block') ?? []
    );
    setSpotRelationConnect(
      panorama.panoramaself1
        .concat(panorama.panoramaself2)
        .filter((item) => item.PanoramaSelf.type === 'connect') ?? []
    );
    setSpotExternalConnect(
      panorama.panoramaself1
        .concat(panorama.panoramaself2)
        .filter((item) => item.PanoramaSelf.type === 'external') ?? []
    );
    setSpotMediaConnect(
      panorama.panoramaself1
        .concat(panorama.panoramaself2)
        .filter((item) => item.PanoramaSelf.type === 'media') ?? []
    );
  };

  const contextPanoramaItem = (e, panorama) => {
    e.preventDefault();
    setAnchorPoint({ x: e.pageX, y: e.pageY });
    setShow(true);
    setCoordinateContext({
      x: panorama.x,
      y: panorama.y,
    });
    setIdSelectedContext(panorama.id);
    setImageLinkContext(panorama.img_original ?? '');
  };

  const setInitState = (refetch_flag) => {
    setRelationBlockAttachValue('');
    setRelationConnectAttachValue('');
    setRelationConnectValDir1('');
    setRelationConnectValDir2('');
    setRelationExternalAttachValue('');
    setRelationExternalValDir1('');
    setRelationExternalValDir2('');
    setRelationExternalSpaceIdValue('');
    setImageLinkContext('');
    setIdSelectedContext(0);
    setCoordinateContext({ x: 0, y: 0 });
    if (refetch_flag) refetch();
  };

  // Floor 층수 Click Listener
  const onClickFloorList = (floorClicked) => {
    setFloor(floorClicked);
  };

  const addSubFloorListener = (e) => {
    e.preventDefault();
    if (floor != 99) {
      var subFloorAdded = 0;
      if (floor > 0) {
        subFloorAdded = Number.parseFloat(
          (Number.parseFloat(floor) + 0.1).toFixed(1)
        );
      } else {
        subFloorAdded = Number.parseFloat(
          (Number.parseFloat(floor) - 0.1).toFixed(1)
        );
      }
      if (!floorDropdownData.includes(subFloorAdded)) {
        setFloorDropdownData((prev) => [...prev, subFloorAdded]);
      }
      setFloor(subFloorAdded);
    } else {
      alert('외부 층은 나눌 수 없습니다.');
    }
  };

  const uploaMultiplePanoramaImage = async (e) => {
    setDataLoading(true);
    var temp_list = [...e.target.files];
    for (let i = 0; i < temp_list.length; i++) {
      let file = temp_list[i];
      let fileName = file.name.split('.')[0];
      let tempData = data.filter((item) =>
        item.img_original?.includes(fileName)
      );
      if (tempData && tempData.length > 0) {
        let postDataWithImage = new FormData();
        let headers = {
          id: spaceId,
          panoid: tempData[0].id,
        };
        postDataWithImage.append('img', file);

        await uploadImagePromise(postDataWithImage, headers).then(
          (response) => {
            if (response.data) {
              refetch();
            } else {
              alert(
                '이미지를 업로드하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
              );
            }
          }
        );
      }
    }
    setDataLoading(false);
    alert('이미지 수정 완료');
  };

  const uploadImagePromise = (formData, headers) => {
    return new Promise((resolve, reject) => {
      spaceAPI
        .uploadPanoramaImage(formData, headers)
        .then((response) => resolve(response))
        .catch((e) => {
          reject(e);
        });
    });
  };

  // 파노라마 이미지 업로드
  const uploadPanoramaImage = async (e, x, y) => {
    if (isSubFloor && globalRoomName === '') {
      alert('global room_name을 입력해주세요.');
    } else {
      let error = false;
      // panorama image upload
      let postDataWithImage = new FormData();
      let headers = {
        id: spaceId,
        x: x,
        y: y,
        floor: floor,
        category: 'default',
      };
      postDataWithImage.append('room_name', roomName ?? '');
      postDataWithImage.append('img', e.target.files[0]);
      try {
        await spaceAPI.uploadPanoramaImage(postDataWithImage, headers);
      } catch (e) {
        console.error(e);
        error = true;
      } finally {
        if (error) {
          alert(
            '파노라마 이미지 업로드 도중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
          );
        }
        refetch();
        setDataLoading(false);
      }
    }
  };

  const changePanoramaImage = async (e) => {
    // panorama image upload
    let postDataWithImage = new FormData();
    postDataWithImage.append('img', e.target.files[0]);
    let headers = {
      id: spaceId,
      panoid: idSelectedContext,
    };
    try {
      await spaceAPI.uploadPanoramaImage(postDataWithImage, headers);
    } catch (e) {
      console.error(e);
      alert(
        '파노라마 이미지 업로드 도중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
      );
    } finally {
      refetch();
    }
  };

  const uploadImageListener = async (e) => {
    // panorama image upload
    let postDataWithImage = new FormData();
    postDataWithImage.append('img', e.target.files[0]);
    let headers = {
      panoid: idSelected,
      id: spaceId,
    };
    try {
      let response = await spaceAPI.uploadPanonamaMediaImage(
        postDataWithImage,
        headers
      );
      setMediaImage(response.data.image);
    } catch (e) {
      console.error(e);
      alert(
        '파노라마 미디어 이미지 업로드 도중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
      );
    } finally {
      refetch();
    }
  };

  const deleteMediaImage = async (e) => {
    if (!window.confirm('해당 파노라마 미디어 이미지를 삭제하시겠습니까?')) {
      return;
    }
    let postData = {
      id: idSelected,
    };
    let error = false;
    try {
      const response = await spaceAPI.deletePanoramaMediaImage(postData);
      setMediaImage(response.data.image);
    } catch (e) {
      console.error(e);
      error = true;
    } finally {
      if (error) {
        alert(
          '이미지를 삭제하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
        );
      }
      refetch();
    }
  };

  const uploadVideoPromise = (formData, headers) => {
    return new Promise((resolve, reject) => {
      spaceAPI
        .uploadPanonamaMediaVideo(formData, headers)
        .then((response) => resolve(response))
        .catch((e) => {
          reject(e);
        });
    });
  };

  const uploadVideo = async (e) => {
    setVideoUploadLoading(true);
    let postData = new FormData();
    var temp_file = e.target.files[0];
    let blob = temp_file.slice(0, temp_file.size, temp_file.type);
    postData.append(
      'video',
      new File([blob], idSelected + '_video.' + temp_file.name.split('.').pop())
    );
    let headers = {
      panoid: idSelected,
      id: spaceId,
    };
    let error = false;
    try {
      await uploadVideoPromise(postData, headers).then((response) => {
        if (response.data) {
          setMediaVideo(response.data.video);
        } else {
          alert(
            '미디어 동영상 업로드하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
          );
        }
      });
    } catch (e) {
      console.error(e);
      error = true;
    } finally {
      if (error) {
        alert(
          '미디어 동영상을 업로드하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
        );
      }

      setVideoUploadLoading(false);
    }
  };

  const deleteMediaVideo = async (e) => {
    if (!window.confirm('해당 파노라마 미디어 동영상을 삭제하시겠습니까?')) {
      return;
    }
    let postData = {
      id: idSelected,
    };
    let error = false;
    try {
      const response = await spaceAPI.deletePanoramaMediaVideo(postData);
      setMediaVideo(response.data.video);
    } catch (e) {
      console.error(e);
      error = true;
    } finally {
      if (error) {
        alert(
          '미디어 동영상을 삭제하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
        );
      }
      refetch();
    }
  };

  // 파노라마 이미지 삭제
  const removePanoramaImage = async (e) => {
    e.preventDefault();
    let error = false;
    if (mode === 'default') {
      try {
        await spaceAPI.deletePanoramaImage(idSelectedContext);
      } catch (e) {
        console.error(e);
        error = true;
      } finally {
        if (error) {
          alert(
            '파노라마 이미지 삭제 도중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
          );
        }
        setShowDataEditPanel(false);
        setIdSelected(0);
        setCoordinate({ x: 0, y: 0 });
        setRoomName('');
        setImageLinkContext('');
        setIdSelectedContext(0);
        setCoordinateContext({ x: 0, y: 0 });
        refetch();
      }
    } else {
      try {
        const postData = {
          id: idSelectedContext,
          mode: panorama_values.mode.indexOf(mode),
        };
        await spaceAPI.changePanoramaMode(postData);
      } catch (e) {
        console.error(e);
        error = true;
      } finally {
        if (error) {
          alert(
            '파노라마 이미지 삭제 도중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
          );
        }
        setShowDataEditPanel(false);
        setIdSelected(0);
        setCoordinate({ x: 0, y: 0 });
        setRoomName('');
        setImageLinkContext('');
        setIdSelectedContext(0);
        setCoordinateContext({ x: 0, y: 0 });
        refetch();
      }
    }
  };

  // 파노라마 이미지 정보 수정
  const panoramaEditBtnListener = async (e) => {
    e.preventDefault();
    try {
      const postData = {
        id: idSelected,
        category: roomCategory,
        room_name: roomName,
      };
      await spaceAPI.editPanoramaInfo(postData);
      const postDataRoomName = {
        floor: floor,
        room_name: roomName,
        space_id: spaceId,
      };
      if (isSubFloor) {
        await spaceAPI.editPanoramaRoomNameAll(postDataRoomName);
      }
    } catch (e) {
      console.error(e);
      alert(
        '파노라마 정보를 수정하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
      );
      return;
    }
    alert('파노라마 정보가 수정되었습니다.');
    setRoomName('');
    setImageLinkContext('');
    setIdSelectedContext(0);
    setCoordinateContext({ x: 0, y: 0 });
    refetch();
  };

  const mediaCellEditBtnListener = async (e) => {
    e.preventDefault();
    try {
      const postData = {
        id: idSelected,
        type: type,
        title: title,
        description: desc,
        size: mediaSize,
      };
      await spaceAPI.editPanoramaMediaInfo(postData);
    } catch (e) {
      console.error(e);
      alert(
        '파노라마 미디어 정보를 수정하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
      );
      return;
    }
    alert('파노라마 미디어 정보가 수정되었습니다.');
    setRoomName('');
    setImageLinkContext('');
    setIdSelectedContext(0);
    setCoordinateContext({ x: 0, y: 0 });
    refetch();
  };

  // 파노라마 이미지 정보 수정
  const subFloorNameEditBtnListener = async (e) => {
    e.preventDefault();
    if (panoramaImage.length > 0) {
      try {
        const postDataRoomName = {
          floor: floor,
          room_name: roomName,
          space_id: spaceId,
        };
        await spaceAPI.editPanoramaRoomNameAll(postDataRoomName);
      } catch (e) {
        console.error(e);
        alert(
          '파노라마 정보를 수정하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
        );
        return;
      }
      alert('파노라마 정보가 수정되었습니다.');
      refetch();
    }
  };

  // 파노라마 모두 삭제
  const removeAllBtnListener = async (e) => {
    e.preventDefault();
    let error = false;
    if (
      window.confirm(
        '파노라마 데이터 전부를 삭제합니다. 다시 한번 확인해주세요.'
      )
    ) {
      try {
        await spaceAPI.deletePanoramaImageAll(spaceId);
      } catch (e) {
        console.error(e);
      } finally {
        if (error) {
          alert(
            '파노라마 전체 삭제 도중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
          );
        } else {
          alert('전체 삭제되었습니다.');
        }
        setShowDataEditPanel(false);
        setIdSelected(0);
        setCoordinate({ x: 0, y: 0 });
        setRoomName('');
        setImageLinkContext('');
        setIdSelectedContext(0);
        setCoordinateContext({ x: 0, y: 0 });
        refetch();
      }
    } else {
      alert('취소되었습니다.');
    }
  };
  // 파노라마 모두 삭제
  const loadFromDefaultMode = async (e) => {
    e.preventDefault();
    let error = false;
    if (window.confirm('파노라마 데이터들을 디폴트 모드에서 불러옵니다.')) {
      try {
        const postData = {
          space_id: spaceId,
          mode: panorama_values.mode.indexOf(mode),
        };
        await spaceAPI.loadPanoramaFromDefaultMode(postData);
      } catch (e) {
        console.error(e);
      } finally {
        if (error) {
          alert(
            '파노라마 불러오기 도중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
          );
        } else {
          alert('데이터 불러오기 성공.');
        }
        refetch();
      }
    } else {
      alert('취소되었습니다.');
    }
  };

  const refreshPanorama = async (e) => {
    e.preventDefault();
    let error = false;
    var message =
      mode === 'default'
        ? '다수의 파노라마 데이터가 삭제 될 수 있습니다.\n김윤환님 혹은 백준우님과 상의해 보시고 결정해주세요.'
        : '다수의 파노라마 데이터가 해당 모드에서 제거 됩니다. 다시 한 번 확인해 주세요.';
    if (window.confirm(message)) {
      if (mode === 'default') {
        try {
          await spaceAPI.refreshPanoramaImage(spaceId);
        } catch (e) {
          console.error(e);
        } finally {
          if (error) {
            alert(
              '파노라마 새로고침 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
            );
          } else {
            alert('파노라마 새로고침 완료.');
          }
          setShowDataEditPanel(false);
          setIdSelected(0);
          setCoordinate({ x: 0, y: 0 });
          setRoomName('');
          setImageLinkContext('');
          setIdSelectedContext(0);
          setCoordinateContext({ x: 0, y: 0 });
          refetch();
        }
      } else {
        try {
          const postData = {
            space_id: spaceId,
            mode: panorama_values.mode.indexOf(mode),
          };
          await spaceAPI.formatPanoramaMode(postData);
        } catch (e) {
          console.error(e);
        } finally {
          if (error) {
            alert(
              '파노라마 모드 초기화 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
            );
          } else {
            alert('파노라마 모드 초기화 완료.');
          }
          setShowDataEditPanel(false);
          setIdSelected(0);
          setCoordinate({ x: 0, y: 0 });
          setRoomName('');
          setImageLinkContext('');
          setIdSelectedContext(0);
          setCoordinateContext({ x: 0, y: 0 });
          refetch();
        }
      }
    } else {
      alert('취소되었습니다.');
    }
  };

  // 파노라마 이미지 정보 수정
  const addSubPanorama = async (e, x, y) => {
    e.preventDefault();
    let error = false;
    let postData = {
      space_id: spaceId,
      x: x,
      y: y,
      floor: floor,
      category: 'default',
    };
    let text = x % 1 === 0 && y % 1 === 0 ? '빈 파노라마' : '서브 파노라마';
    if (window.confirm(text + ' 모델을 생성하시겠습니까?')) {
      try {
        await spaceAPI.registerPanorama(postData);
      } catch (e) {
        console.error(e);
      } finally {
        if (error) {
          alert(
            text +
              ' 모델 생성 도중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
          );
        } else {
          alert(text + ' 생성 완료.');
        }
        setShowDataEditPanel(false);
        setIdSelected(0);
        setCoordinate({ x: 0, y: 0 });
        setRoomName('');
        setImageLinkContext('');
        setIdSelectedContext(0);
        setCoordinateContext({ x: 0, y: 0 });
        refetch();
      }
    } else {
      alert('취소되었습니다.');
    }
  };

  const attachBlockBtnListener = async (e) => {
    e.preventDefault();
    if (idSelected !== relationBlockAttachValue * 1) {
      const postData = {
        spot_id: idSelected,
        attach_id: relationBlockAttachValue * 1,
        space_id: spaceId,
      };
      try {
        await spaceAPI.attachPanoramaRelationBlock(postData);
      } catch (e) {
        console.error(e);
        alert(
          '파노라마 정보를 수정하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
        );
        return;
      }
      alert('파노라마 Relation(block)이 추가되었습니다.');
      setRelationBlockAttachValue('');
      setImageLinkContext('');
      setIdSelectedContext(0);
      setCoordinateContext({ x: 0, y: 0 });
      refetch();
    } else {
      alert(
        '현재 파노라마와 입력된 id값이 동일합니다.\n다시 한번 확인해주세요.'
      );
    }
  };

  const attachMediaBtnListener = async (e) => {
    e.preventDefault();
    if (idSelected !== relationPanoVal * 1) {
      const postData = {
        pano_id: relationPanoVal * 1,
        media_id: idSelected,
        space_id: spaceId,
        latitude: latitude,
        longitude: longitude,
      };
      try {
        await spaceAPI.attachPanoramaRelationMedia(postData);
      } catch (e) {
        console.error(e);
        alert(
          '파노라마 정보를 수정하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
        );
        return;
      }
      alert('파노라마 Relation(Media)이 추가되었습니다.');
      setRelationPanoVal('');
      setLatitude('');
      setLongitude('');
      setImageLinkContext('');
      setIdSelectedContext(0);
      setCoordinateContext({ x: 0, y: 0 });
      refetch();
    } else {
      alert(
        '현재 파노라마와 입력된 id값이 동일합니다.\n다시 한번 확인해주세요.'
      );
    }
  };

  const detachRelationBtnListener = async (e, relation_id) => {
    e.preventDefault();
    const postData = {
      relation_id: relation_id,
      space_id: spaceId,
    };
    try {
      await spaceAPI.detachPanoramaRelation(postData);
    } catch (e) {
      console.error(e);
    }
    alert('파노라마 Relation이 삭제되었습니다.');
    setRelationBlockAttachValue('');
    setRelationConnectAttachValue('');
    setRelationConnectValDir1('');
    setRelationConnectValDir2('');
    setRelationExternalAttachValue('');
    setRelationExternalValDir1('');
    setRelationExternalValDir2('');
    setRelationExternalSpaceIdValue('');
    setImageLinkContext('');
    setIdSelectedContext(0);
    setCoordinateContext({ x: 0, y: 0 });
    refetch();
  };

  const mediaRelationFix = async (e, item) => {
    e.preventDefault();
    setRelationPanoVal(item.PanoramaSelf.s_id1);
    setLatitude(item.PanoramaSelf.target_latitude);
    setLongitude(item.PanoramaSelf.target_longitude);

    const postData = {
      relation_id: item.PanoramaSelf.id,
      space_id: spaceId,
    };
    try {
      await spaceAPI.detachPanoramaRelation(postData);
    } catch (e) {
      console.error(e);
    }
    alert('파노라마 Relation이 삭제되었습니다.');
    setRelationBlockAttachValue('');
    setRelationConnectAttachValue('');
    setRelationConnectValDir1('');
    setRelationConnectValDir2('');
    setRelationExternalAttachValue('');
    setRelationExternalValDir1('');
    setRelationExternalValDir2('');
    setRelationExternalSpaceIdValue('');
    setImageLinkContext('');
    setIdSelectedContext(0);
    setCoordinateContext({ x: 0, y: 0 });
    refetch();
  };

  const attachConnectBtnListener = async (e) => {
    e.preventDefault();
    if (idSelected !== relationConnectAttachValue * 1) {
      const postData = {
        spot_id: idSelected,
        attach_id: relationConnectAttachValue,
        space_id: spaceId,
        dir_1: relationConnectValDir1,
        dir_2: relationConnectValDir2,
      };
      try {
        await spaceAPI.attachPanoramaRelationConnect(postData);
      } catch (e) {
        console.error(e);
        alert(
          '파노라마 정보를 수정하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
        );
        return;
      }
      alert('파노라마 Relation(connect)이 추가되었습니다.');
      setRelationConnectAttachValue('');
      setRelationConnectValDir1('');
      setRelationConnectValDir2('');
      setImageLinkContext('');
      setIdSelectedContext(0);
      setCoordinateContext({ x: 0, y: 0 });
      refetch();
    } else {
      alert('id1, id2가 동일합니다.\n다시 한번 확인해주세요.');
    }
  };

  const attachExternalBtnListener = async (e) => {
    e.preventDefault();
    const postData = {
      spot_id: idSelected,
      attach_id: relationExternalAttachValue,
      space_id: spaceId,
      dir_1: relationExternalValDir1,
      dir_2: relationExternalValDir2,
      external_id: relationExternalSpaceIdValue,
    };
    try {
      await spaceAPI.attachPanoramaRelationExternal(postData);
    } catch (e) {
      console.error(e);
      alert(
        '파노라마 정보를 수정하는 중, 에러가 발생했습니다.\n관리자에게 문의하세요.'
      );
      return;
    }
    alert('파노라마 Relation(external)이 추가되었습니다.');
    setRelationExternalAttachValue('');
    setRelationExternalValDir1('');
    setRelationExternalValDir2('');
    setRelationExternalSpaceIdValue('');
    setImageLinkContext('');
    setIdSelectedContext(0);
    setCoordinateContext({ x: 0, y: 0 });
    refetch();
  };

  return (
    <ModalContainer>
      {/* 우클릭  */}
      <CustomContextMenu
        style={{
          display: show ? 'block' : 'none',
          top: anchorPoint.y,
          left: anchorPoint.x,
        }}
      >
        <CustomContextMenuItem>
          {`( x, y ) = ( ${coordinateContext.x}, ${coordinateContext.y} )`}
        </CustomContextMenuItem>
        {coordinateContext.x % 1 === 0 && coordinateContext.y % 1 === 0 && (
          <>
            <CustomContextMenuItem
              onClick={(e) => setRelationBlockAttachValue(idSelectedContext)}
            >
              블락 id로 추가
            </CustomContextMenuItem>
            <CustomContextMenuItem
              onClick={(e) => setRelationConnectAttachValue(idSelectedContext)}
            >
              커넥트 id로 추가
            </CustomContextMenuItem>
          </>
        )}
        <CustomContextMenuItem>
          <a href={imageLinkContext} download>
            사진 다운로드
          </a>
        </CustomContextMenuItem>
        {mode === 'default' && (
          <CustomContextMenuItem>
            <label htmlFor="change-panorama-image">
              사진 변경
              <ImageUploadInput
                type="file"
                id="change-panorama-image"
                onClick={(e) => {
                  e.target.value = null;
                }}
                onChange={(e) => changePanoramaImage(e)}
              />
            </label>
          </CustomContextMenuItem>
        )}
        {mode !== 'default' && (
          <CustomContextMenuItem onClick={(e) => removePanoramaImage(e)}>
            디폴트에서 불러오기
          </CustomContextMenuItem>
        )}
        <CustomContextMenuItem onClick={(e) => removePanoramaImage(e)}>
          {mode === 'default' ? '삭제' : '모드에서 제거'}
        </CustomContextMenuItem>
      </CustomContextMenu>
      <HeaderContainer>
        <HeaderRow space_between>
          <GlobalSettingWrapper>
            <ContainerTitle>FAVIEW 데이터 관리</ContainerTitle>
            {/* 그리드 사이즈 선택 */}
            <Dropdown value={size} onChange={sizeOnChange} margin_left={'12px'}>
              {sizeDropdownData.map((data, idx) => (
                <option value={data} key={'size_' + idx}>
                  {data * 2 + 1} X {data * 2 + 1}
                </option>
              ))}
            </Dropdown>
            <LinkButton
              color={palette.black}
              onClick={(e) => {
                setShowSubPano(!showSubPano);
              }}
            >
              {showSubPano ? '소수포인트 숨기기' : '소수포인트 보기'}
            </LinkButton>
          </GlobalSettingWrapper>
          <GlobalSettingWrapper>
            {/* 버튼 모음 */}
            {mode === 'default' && (
              <>
                <MultipleImageUploadButton
                  htmlFor="multiple-panorama-image-change"
                  color={palette.mainOrange}
                >
                  사진 업로드
                </MultipleImageUploadButton>
                <MultipleImageUploadInput
                  type="file"
                  id="multiple-panorama-image-change"
                  multiple={true}
                  onChange={(e) => uploaMultiplePanoramaImage(e)}
                  onClick={(e) => {
                    e.target.value = null;
                  }}
                />
                <LinkButton
                  color={palette.mint}
                  onClick={(e) => refreshPanorama(e)}
                >
                  새로고침
                </LinkButton>
                <LinkButton
                  color={palette.mainBlue}
                  onClick={() =>
                    window.open(
                      pagePaths.faview_admin.replace(':id', spaceId),
                      '_blank'
                    )
                  }
                >
                  FAVIEW
                </LinkButton>
                <LinkButton
                  color={palette.fontWarning}
                  onClick={(e) => removeAllBtnListener(e)}
                >
                  Delete All
                </LinkButton>
              </>
            )}
            {mode !== 'default' && (
              <>
                <LinkButton
                  color={palette.mainBlue}
                  onClick={() =>
                    window.open(
                      pagePaths.faview_admin.replace(':id', spaceId),
                      '_blank'
                    )
                  }
                >
                  FAVIEW
                </LinkButton>
                <LinkButton
                  color={palette.mint}
                  onClick={(e) => refreshPanorama(e)}
                >
                  모드 초기화
                </LinkButton>
                <LinkButton
                  color={palette.fontWarning}
                  onClick={(e) => loadFromDefaultMode(e)}
                >
                  불러오기
                </LinkButton>
              </>
            )}
            <CloseButton onClick={() => closeCurrentModal()}>✕</CloseButton>
          </GlobalSettingWrapper>
        </HeaderRow>
        <HeaderRow>
          {/* 층 선택 */}
          <GlobalSettingWrapper>
            <Dropdown value={mode} onChange={modeOnChange}>
              {panorama_values.mode.map((data, idx) => (
                <option value={data} key={'mode_' + idx}>
                  {data}
                </option>
              ))}
            </Dropdown>
            <Dropdown value={floor} onChange={floorOnChange}>
              {floorDropdownData.map((data, idx) => (
                <option value={data} key={'floor_' + idx}>
                  {data !== 99
                    ? String(data).replace('-', '지하 ') + '층'
                    : '외부'}
                </option>
              ))}
            </Dropdown>
            <FloorListWrapper>
              {floorList.map((floor, idx) => {
                var floorString = floor;
                if (floor == 99) floorString = '외부';
                return (
                  <FloorList
                    onClick={() => onClickFloorList(floor)}
                    key={'floorList_' + idx}
                  >
                    {floorString}
                  </FloorList>
                );
              })}
            </FloorListWrapper>
            <AddSubFloorBtn onClick={(e) => addSubFloorListener(e)}>
              +
            </AddSubFloorBtn>
          </GlobalSettingWrapper>
          {isSubFloor && (
            <GlobalSettingWrapper margin_left={'20px'}>
              <GlobalSettingTitle>room_name</GlobalSettingTitle>
              <DetailTextarea
                width="120px"
                height="14px"
                font_size="13px"
                value={globalRoomName}
                onChange={globalRoomNameOnChange}
              />
              <Button onClick={subFloorNameEditBtnListener}>수정</Button>
            </GlobalSettingWrapper>
          )}
        </HeaderRow>
        {/* Data Loading... */}
        {dataLoading && (
          <LoadingWrapper>
            <TailSpin color={palette.mainBlue} width={50} height={50} />
          </LoadingWrapper>
        )}
      </HeaderContainer>

      <PanoramaContainer>
        {/* Panorama Grid */}
        <GridWrapper showSubPano={showSubPano}>
          {panoramaImage &&
            (showSubPano
              ? Array.from(
                  { length: 2 * (parseFloat(size) * 2 + 1) + 1 },
                  (_, i) => (i - (parseFloat(size) * 2 + 1)) / 2
                ).map((n, x) => {
                  return (
                    <GridRow showSubPano={showSubPano} key={'x_' + n}>
                      {Array.from(
                        { length: 2 * (parseInt(size) * 2 + 1) + 1 },
                        (_, i) => (parseInt(size) * 2 + 1 - i) / 2
                      ).map((m, y) => {
                        let pano_item = panoramaImage.filter(
                          (data) => data.x === n && data.y === m
                        );
                        if (m % 1 === 0 && n % 1 === 0) {
                          if (pano_item.length === 1) {
                            return (
                              <div key={'y1_' + m}>
                                <GridCell
                                  htmlFor={`upload-panorama-image-${n}-${m}`}
                                  uploadedImg={encodeURI(
                                    pano_item[0]?.img_thumbnail
                                  )}
                                  onClick={(e) =>
                                    clickPanoramaItem(e, pano_item[0])
                                  }
                                  onContextMenu={(e) =>
                                    contextPanoramaItem(e, pano_item[0])
                                  }
                                >
                                  ({n},{m})
                                </GridCell>
                              </div>
                            );
                          } else {
                            return (
                              <div key={'y2_' + m}>
                                <GridCell
                                  htmlFor={`upload-panorama-image-${n}-${m}`}
                                  uploadedImg={''}
                                  onContextMenu={(e) => addSubPanorama(e, n, m)}
                                >
                                  ({n},{m})
                                </GridCell>
                                <GridCellImageInput
                                  type="file"
                                  id={`upload-panorama-image-${n}-${m}`}
                                  onChange={(e) => uploadPanoramaImage(e, n, m)}
                                />
                              </div>
                            );
                          }
                        } else {
                          if (pano_item.length === 1) {
                            return (
                              <div key={'y3_' + m}>
                                <GridFloatCell
                                  exist={true}
                                  disabled_icon={
                                    pano_item[0].category === 'parking_disabled'
                                  }
                                  uploadedImg={encodeURI(
                                    pano_item[0]?.img_thumbnail
                                  )}
                                  onClick={(e) =>
                                    clickPanoramaItem(e, pano_item[0])
                                  }
                                  onContextMenu={(e) =>
                                    contextPanoramaItem(e, pano_item[0])
                                  }
                                >
                                  {pano_item[0].category === 'parking' &&
                                  pano_item[0].room_name === ''
                                    ? 'P'
                                    : pano_item[0]?.room_name ?? '확인'}
                                </GridFloatCell>
                              </div>
                            );
                          } else {
                            return (
                              <div key={'y4_' + m}>
                                <GridFloatCell
                                  onClick={(e) => addSubPanorama(e, n, m)}
                                >
                                  추가
                                </GridFloatCell>
                              </div>
                            );
                          }
                        }
                      })}
                    </GridRow>
                  );
                })
              : Array.from(
                  { length: parseFloat(size) * 2 + 1 },
                  (_, i) => i - parseFloat(size)
                ).map((n, x) => {
                  return (
                    <GridRow showSubPano={showSubPano} key={'x_' + n}>
                      {Array.from(
                        { length: parseInt(size) * 2 + 1 },
                        (_, i) => parseInt(size) - i
                      ).map((m, y) => {
                        let pano_item = panoramaImage.filter(
                          (data) => data.x === n && data.y === m
                        );
                        if (pano_item.length === 1) {
                          return (
                            <div key={'y1_' + m}>
                              <GridCellInteger
                                htmlFor={`upload-panorama-image-${n}-${m}`}
                                uploadedImg={encodeURI(
                                  pano_item[0]?.img_thumbnail
                                )}
                                onClick={(e) =>
                                  clickPanoramaItem(e, pano_item[0])
                                }
                                onContextMenu={(e) =>
                                  contextPanoramaItem(e, pano_item[0])
                                }
                              >
                                ({n},{m})
                              </GridCellInteger>
                            </div>
                          );
                        } else {
                          return (
                            <div key={'y2_' + m}>
                              <GridCellInteger
                                htmlFor={`upload-panorama-image-${n}-${m}`}
                                uploadedImg={''}
                                onContextMenu={(e) => addSubPanorama(e, n, m)}
                              >
                                ({n},{m})
                              </GridCellInteger>
                              <GridCellImageInput
                                type="file"
                                id={`upload-panorama-image-${n}-${m}`}
                                onChange={(e) => uploadPanoramaImage(e, n, m)}
                              />
                            </div>
                          );
                        }
                      })}
                    </GridRow>
                  );
                }))}
        </GridWrapper>
        {showDataEditPanel && (
          <EditWrapper>
            <EditTitleWrapper>
              <DetailTitle center>촬영본 정보</DetailTitle>
              <DetailCloseButton onClick={() => setShowDataEditPanel(false)}>
                &#60;
              </DetailCloseButton>
            </EditTitleWrapper>
            <EditDataWrapper>
              <RowWrapper>
                {/* 촬영본 정보 */}
                <ElementWrapper>
                  <DetailSection>
                    <DetailLabel>Panorama ID</DetailLabel>
                    <DetailValue>{idSelected}</DetailValue>
                  </DetailSection>
                  <DetailSection>
                    <DetailLabel>Coordinate(x,y)</DetailLabel>
                    <DetailValue>
                      ({coordinate.x}, {coordinate.y})
                    </DetailValue>
                  </DetailSection>
                  <DetailSection>
                    <DetailLabel>층 (floor)</DetailLabel>
                    <DetailValue>{floor}</DetailValue>
                  </DetailSection>
                </ElementWrapper>

                <ElementWrapper>
                  <DetailTitle>타입 (type)</DetailTitle>
                  <Dropdown
                    value={type}
                    onChange={typeOnChange}
                    margin_top={'10px'}
                  >
                    {typeDropdownData.map((data, idx) => (
                      <option value={data} key={'type_' + idx}>
                        {data}
                      </option>
                    ))}
                  </Dropdown>
                </ElementWrapper>

                {/* 카테고리 */}
                {type === 'normal' && (
                  <ElementWrapper>
                    <DetailTitle>카테고리 (category)</DetailTitle>
                    <HelpButton
                      onMouseOver={() => setIsHoveringHelp(0)}
                      onMouseOut={() => setIsHoveringHelp(-1)}
                    >
                      ?
                    </HelpButton>
                    <Dropdown
                      value={roomCategory}
                      onChange={roomCategoryOnChange}
                      margin_top={'10px'}
                    >
                      {coordinate.x % 1 === 0 && coordinate.y % 1 === 0
                        ? categoryDropdownData.map((data, idx) => (
                            <option value={data} key={'category_' + idx}>
                              {data}
                            </option>
                          ))
                        : subCategoryDropdownData.map((data, idx) => (
                            <option value={data} key={'category_' + idx}>
                              {data}
                            </option>
                          ))}
                    </Dropdown>
                    {isHoveringHelp === 0 && (
                      <HelpWrapper>
                        <HelpDescription>
                          구분되지 않고 이어지는 내부 매장의 경우 default로 모두
                          통일합니다.
                          <br />
                          <br />
                          entrance : 방을 포함한 문을 열고 들어간 위치의 모든
                          cell에 해당합니다.
                          <br />
                          exterior : 공간 밖의 외부에 위치한 cell을 표시합니다.
                          <br />
                          room : 한 층에서 방이 구분되어져야 하는 경우 room으로
                          표기하고 room_name에 표기할 방 이름을 작성합니다.
                        </HelpDescription>
                      </HelpWrapper>
                    )}
                  </ElementWrapper>
                )}
                {type === 'normal' ? (
                  <ElementWrapper>
                    <DetailSubTitle>&#62; 방 이름 (room_name)</DetailSubTitle>
                    <EditButtonWrapper>
                      {!isSubFloor ? (
                        <DetailTextarea
                          value={roomName}
                          onChange={roomNameOnChange}
                        />
                      ) : (
                        <DetailTextarea
                          value={roomName}
                          onChange={roomNameOnChange}
                          readOnly
                          font_color={palette.fontBlue}
                        />
                      )}
                      <Button onClick={panoramaEditBtnListener}>수정</Button>
                    </EditButtonWrapper>
                  </ElementWrapper>
                ) : (
                  <>
                    <ElementWrapper>
                      <DetailSubTitle>&#62; 제목 (title)</DetailSubTitle>
                      <EditButtonWrapper>
                        <DetailTextarea
                          value={title}
                          onChange={titleOnChange}
                        />
                      </EditButtonWrapper>
                    </ElementWrapper>
                    <ElementWrapper>
                      <DetailSubTitle>&#62; 사이즈 (size)</DetailSubTitle>
                      <EditButtonWrapper>
                        <DetailTextarea
                          value={mediaSize}
                          onChange={mediaSizeOnChange}
                        />
                      </EditButtonWrapper>
                    </ElementWrapper>
                    <ElementWrapper>
                      <DetailSubTitle>&#62; 설명 (description)</DetailSubTitle>
                      <EditButtonWrapper>
                        <MediaDescTextarea
                          value={desc}
                          onChange={descOnChange}
                        />
                        <Button onClick={mediaCellEditBtnListener}>수정</Button>
                      </EditButtonWrapper>
                    </ElementWrapper>
                    {type === 'image' && (
                      <ImageWrapper>
                        <ImageContents
                          uploadedImg={mediaImage}
                          key={mediaImage}
                        />
                        <ImageUploadButton
                          htmlFor="upload-media-image"
                          icon={icon_imgadd}
                        />

                        <ImageDeleteButton
                          onClick={(e) => deleteMediaImage(e)}
                        />

                        <ImageUploadInput
                          type="file"
                          id="upload-media-image"
                          multiple={true}
                          onChange={(e) => uploadImageListener(e)}
                          onClick={(e) => {
                            e.target.value = null;
                          }}
                        />
                      </ImageWrapper>
                    )}
                    {type === 'video' && (
                      <>
                        {videoUploadLoading && (
                          <ContentsLoadingWrapper>
                            <TailSpin
                              color={palette.mainBlue}
                              width={16}
                              height={16}
                            />
                            영상 업로드 중...
                          </ContentsLoadingWrapper>
                        )}

                        <ImageWrapper>
                          <VideoContents key={mediaVideo} controls>
                            <source src={mediaVideo} />
                          </VideoContents>

                          <VideoUploadButton
                            htmlFor="upload-media-video"
                            icon={icon_imgadd}
                          />

                          <VideoDeleteButton
                            size="30"
                            onClick={(e) => deleteMediaVideo(e)}
                          />

                          <VideoUploadInput
                            type="file"
                            id="upload-media-video"
                            accept="video/mp4,video/mkv, video/x-m4v, video/*"
                            onChange={(e) => uploadVideo(e)}
                            onClick={(e) => {
                              // e.target.value = null;
                            }}
                          />
                        </ImageWrapper>
                      </>
                    )}
                  </>
                )}

                {/* Block Relation */}
                {type === 'normal' && (
                  <ElementWrapper>
                    <DetailTitle>BLOCK RELATION</DetailTitle>
                    <AttachWrapper>
                      <DetailDescription>
                        Block할 위치의 spot_id를 입력해주세요.
                      </DetailDescription>
                      <EditButtonWrapper direction={'row'}>
                        <DetailTextarea
                          value={relationBlockAttachValue}
                          onChange={relationBlockAttachValueOnChange}
                        />
                        <Button onClick={attachBlockBtnListener}>추가</Button>
                      </EditButtonWrapper>
                    </AttachWrapper>
                    <RelationWrapper>
                      {spotRelationBlock &&
                        spotRelationBlock.map((item, idx) => {
                          return (
                            <RelationItemWrapper key={'relation_' + idx}>
                              <RelationItem>
                                {item.id} ({item.x}, {item.y})
                              </RelationItem>
                              <DetachButton
                                onClick={(e) =>
                                  detachRelationBtnListener(
                                    e,
                                    item.PanoramaSelf.id
                                  )
                                }
                              >
                                -
                              </DetachButton>
                            </RelationItemWrapper>
                          );
                        })}
                    </RelationWrapper>
                  </ElementWrapper>
                )}
              </RowWrapper>
              <div style={{ width: '20px' }}></div>
              {/* Connect Relation */}

              <RowWrapper>
                {type === 'normal' ? (
                  <>
                    <ElementWrapper>
                      <DetailTitle>CONNECT RELATION</DetailTitle>
                      <HelpButton
                        onMouseOver={() => setIsHoveringHelp(1)}
                        onMouseOut={() => setIsHoveringHelp(-1)}
                      >
                        ?
                      </HelpButton>

                      {isHoveringHelp === 1 && (
                        <HelpWrapper>
                          <HelpDescription>
                            Connect dir 에 들어가는 방향은 <br />
                            아래와 같습니다.
                            <br />
                            | 8 1 2 |<br />| 7 _ 3 |<br />| 6 5 4 |
                          </HelpDescription>
                        </HelpWrapper>
                      )}
                      <AttachWrapper>
                        <DetailDescription>
                          Connect할 위치의 panorama id를 입력해주세요.
                          <br />
                          id2: 커넥트할 위치의 pano id
                          <br />
                          dir2: id2 파노에서 버튼 위치
                        </DetailDescription>
                        <EditButtonWrapper direction={'row'}>
                          <DetailTextareaWrapper>
                            <DetailTextareaDescConnect>
                              id1
                            </DetailTextareaDescConnect>
                            <DetailTextarea
                              font_size="11px"
                              font_color={palette.fontBlue}
                              readOnly={true}
                              value={idSelected}
                            />
                          </DetailTextareaWrapper>
                          <DetailTextareaWrapper>
                            <DetailTextareaDescConnect>
                              id2
                            </DetailTextareaDescConnect>
                            <DetailTextarea
                              font_size="11px"
                              value={relationConnectAttachValue}
                              onChange={relationConnectAttachValueOnChange}
                            />
                          </DetailTextareaWrapper>
                          <DetailTextareaWrapper>
                            <DetailTextareaDescConnect>
                              dir1
                            </DetailTextareaDescConnect>
                            <DetailTextarea
                              font_size="11px"
                              value={relationConnectValDir1}
                              onChange={relationConnectValDir1OnChange}
                            />
                          </DetailTextareaWrapper>
                          <DetailTextareaWrapper>
                            <DetailTextareaDescConnect>
                              dir2
                            </DetailTextareaDescConnect>
                            <DetailTextarea
                              font_size="11px"
                              value={relationConnectValDir2}
                              onChange={relationConnectValDir2OnChange}
                            />
                          </DetailTextareaWrapper>
                        </EditButtonWrapper>
                        <Button
                          style={{
                            width: '40px',
                            height: '20px',
                            marginTop: '3px',
                          }}
                          onClick={attachConnectBtnListener}
                        >
                          추가
                        </Button>
                      </AttachWrapper>
                      <RelationWrapper>
                        {spotRelationConnect &&
                          spotRelationConnect.map((item, idx) => {
                            return (
                              <RelationItemWrapper
                                key={'relation_connect_' + idx}
                              >
                                <RelationItem>
                                  {item.id === item.PanoramaSelf.s_id1
                                    ? `${item.PanoramaSelf.s_id2} / ${item.PanoramaSelf.dir_2} : ${item.PanoramaSelf.s_id1} / ${item.PanoramaSelf.dir_1}`
                                    : `${item.PanoramaSelf.s_id1} / ${item.PanoramaSelf.dir_1} : ${item.PanoramaSelf.s_id2} / ${item.PanoramaSelf.dir_2}`}
                                </RelationItem>
                                <DetachButton
                                  onClick={(e) =>
                                    detachRelationBtnListener(
                                      e,
                                      item.PanoramaSelf.id
                                    )
                                  }
                                >
                                  -
                                </DetachButton>
                              </RelationItemWrapper>
                            );
                          })}
                      </RelationWrapper>
                    </ElementWrapper>
                    {/* External Relation */}
                    <ElementWrapper>
                      <DetailTitle>External RELATION</DetailTitle>
                      <HelpButton
                        onMouseOver={() => setIsHoveringHelp(2)}
                        onMouseOut={() => setIsHoveringHelp(-1)}
                      >
                        ?
                      </HelpButton>

                      {isHoveringHelp === 2 && (
                        <HelpWrapper>
                          <HelpDescription>
                            Connect dir 에 들어가는 방향은 <br />
                            아래와 같습니다.
                            <br />
                            | 8 1 2 |<br />| 7 _ 3 |<br />| 6 5 4 |
                          </HelpDescription>
                        </HelpWrapper>
                      )}
                      <AttachWrapper>
                        <DetailDescription>
                          Connect할 외부 공간의 space id와
                          <br />
                          해당 위치의 panorama id를 입력해주세요.
                          <br />
                          sid: 외부 공간 id
                          <br />
                          id2: 커넥트할 위치의 pano id
                          <br />
                          dir2: id2 파노에서 버튼 위치
                        </DetailDescription>
                        <EditButtonWrapper direction={'row'}>
                          <DetailTextareaWrapper>
                            <DetailTextareaDescConnect>
                              id1
                            </DetailTextareaDescConnect>
                            <DetailTextarea
                              font_size="11px"
                              font_color={palette.fontBlue}
                              readOnly={true}
                              value={idSelected}
                            />
                          </DetailTextareaWrapper>
                          <DetailTextareaWrapper>
                            <DetailTextareaDescConnect>
                              id2
                            </DetailTextareaDescConnect>
                            <DetailTextarea
                              font_size="11px"
                              value={relationExternalAttachValue}
                              onChange={relationExternalAttachValueOnChange}
                            />
                          </DetailTextareaWrapper>

                          <DetailTextareaWrapper>
                            <DetailTextareaDescConnect>
                              dir1
                            </DetailTextareaDescConnect>
                            <DetailTextarea
                              font_size="11px"
                              value={relationExternalValDir1}
                              onChange={relationExternalValDir1OnChange}
                            />
                          </DetailTextareaWrapper>
                          <DetailTextareaWrapper>
                            <DetailTextareaDescConnect>
                              dir2
                            </DetailTextareaDescConnect>
                            <DetailTextarea
                              font_size="11px"
                              value={relationExternalValDir2}
                              onChange={relationExternalValDir2OnChange}
                            />
                          </DetailTextareaWrapper>
                          <DetailTextareaWrapper>
                            <DetailTextareaDescConnect>
                              sid
                            </DetailTextareaDescConnect>
                            <DetailTextarea
                              font_size="11px"
                              value={relationExternalSpaceIdValue}
                              onChange={relationExternalSpaceIdValueOnChange}
                            />
                          </DetailTextareaWrapper>
                        </EditButtonWrapper>
                        <Button
                          style={{
                            width: '40px',
                            height: '20px',
                            marginTop: '3px',
                          }}
                          onClick={attachExternalBtnListener}
                        >
                          추가
                        </Button>
                      </AttachWrapper>
                      <RelationWrapper>
                        {spotExternalConnect &&
                          spotExternalConnect.map((item, idx) => {
                            return (
                              <RelationItemWrapper
                                key={'relation_external_' + idx}
                              >
                                <RelationItem>
                                  {item.id === item.PanoramaSelf.s_id1
                                    ? `${item.PanoramaSelf.external_space_id} / ${item.PanoramaSelf.s_id2} / ${item.PanoramaSelf.dir_2} : ${item.PanoramaSelf.space_id} / ${item.PanoramaSelf.s_id1} / ${item.PanoramaSelf.dir_1}`
                                    : `${item.PanoramaSelf.space_id} / ${item.PanoramaSelf.s_id1} / ${item.PanoramaSelf.dir_1} : ${item.PanoramaSelf.external_space_id} / ${item.PanoramaSelf.s_id2} / ${item.PanoramaSelf.dir_2}`}
                                </RelationItem>
                                <DetachButton
                                  onClick={(e) =>
                                    detachRelationBtnListener(
                                      e,
                                      item.PanoramaSelf.id
                                    )
                                  }
                                >
                                  -
                                </DetachButton>
                              </RelationItemWrapper>
                            );
                          })}
                      </RelationWrapper>
                    </ElementWrapper>
                  </>
                ) : (
                  <ElementWrapper>
                    <DetailTitle>MEDIA RELATION</DetailTitle>
                    <AttachWrapper>
                      <DetailDescription>
                        Connect할 위치의 panorama id를 입력해주세요.
                        <br />
                        id2: 커넥트할 위치의 pano id
                        <br />
                        dir2: id2 파노에서 버튼 위치
                      </DetailDescription>
                      <EditButtonWrapper direction={'row'}>
                        <DetailTextareaWrapper>
                          <DetailTextareaDescConnect>
                            media
                          </DetailTextareaDescConnect>
                          <DetailTextarea
                            font_size="11px"
                            font_color={palette.fontBlue}
                            readOnly={true}
                            value={idSelected}
                          />
                        </DetailTextareaWrapper>
                        <DetailTextareaWrapper>
                          <DetailTextareaDescConnect>
                            pano
                          </DetailTextareaDescConnect>
                          <DetailTextarea
                            font_size="11px"
                            value={relationPanoVal}
                            onChange={relationPanoValOnChange}
                          />
                        </DetailTextareaWrapper>
                        <DetailTextareaWrapper>
                          <DetailTextareaDescConnect>
                            latitude
                          </DetailTextareaDescConnect>
                          <DetailTextarea
                            font_size="11px"
                            value={latitude}
                            onChange={latitudeOnChange}
                          />
                        </DetailTextareaWrapper>
                        <DetailTextareaWrapper>
                          <DetailTextareaDescConnect>
                            longitude
                          </DetailTextareaDescConnect>
                          <DetailTextarea
                            font_size="11px"
                            value={longitude}
                            onChange={longitudeOnChange}
                          />
                        </DetailTextareaWrapper>
                      </EditButtonWrapper>
                      <Button
                        style={{
                          width: '40px',
                          height: '20px',
                          marginTop: '3px',
                        }}
                        onClick={attachMediaBtnListener}
                      >
                        추가
                      </Button>
                    </AttachWrapper>
                    <RelationWrapper>
                      {spotMediaConnect &&
                        spotMediaConnect.map((item, idx) => {
                          return (
                            <RelationItemWrapper key={'relation_media_' + idx}>
                              <RelationItem>
                                {`${item.PanoramaSelf.s_id1} / LatLng(${item.PanoramaSelf.target_latitude}, ${item.PanoramaSelf.target_longitude})`}
                              </RelationItem>
                              <DetachButton
                                onClick={(e) =>
                                  detachRelationBtnListener(
                                    e,
                                    item.PanoramaSelf.id
                                  )
                                }
                              >
                                -
                              </DetachButton>
                              <DetachButton
                                backgroundColor={'blue'}
                                fontSize={'10px'}
                                onClick={(e) => mediaRelationFix(e, item)}
                              >
                                수정
                              </DetachButton>
                            </RelationItemWrapper>
                          );
                        })}
                    </RelationWrapper>
                  </ElementWrapper>
                )}
              </RowWrapper>
            </EditDataWrapper>
          </EditWrapper>
        )}
      </PanoramaContainer>
    </ModalContainer>
  );
};

const ModalContainer = styled.div`
  max-height: 100vh;
`;
//// HEADER Container
const HeaderContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  overflow: hidden;
`;
const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: space-between;
  margin-bottom: 12px;
  justify-content: ${(props) => (props.space_between ? 'space-between' : '')};
`;
const CloseButton = styled.div`
  cursor: pointer;
  font-size: 20px;
  margin-left: 8px;
`;
const ContainerTitle = styled.div`
  font-size: 20px;
  font-weight: 800;
  color: ${palette.fontDefault};
  @media screen and (max-width: 400px) {
    font-size: 14px;
  }
`;
const LoadingWrapper = styled.div`
  margin-left: 10px;
  width: 24px;
  height: 24px;
  position: absolute;
  left: 180px;
  top: -10px;
`;
const GlobalSettingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  margin-left: ${(props) => (props.margin_left ? props.margin_left : '0px')};
  margin-right: ${(props) => (props.margin_right ? props.margin_right : '0px')};
`;
const GlobalSettingTitle = styled.div`
  font-size: 14px;
  color: ${palette.fontDefault};
  font-weight: 800;
  display: flex;
  align-items: center;
`;
const FloorListWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
const FloorList = styled.div`
  padding: 3px 8px;
  border-radius: 20px;
  background-color: ${palette.mainBlue};
  color: ${palette.white};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  cursor: pointer;
  &:hover {
    background-color: ${palette.mainBlueHover};
  }
`;
const AddSubFloorBtn = styled.div`
  display: flex;
  align-items: center;
  background-color: ${palette.grey_lighter};
  &:hover {
    background-color: ${palette.mainBlueLight};
  }
  color: ${palette.fontWhite};
  font-size: 14px;
  padding: 0 2px;
  margin-left: 8px;
  cursor: pointer;
  border-radius: 12px;
`;

const MultipleImageUploadButton = styled.label`
  color: ${(props) => (props.color ? props.color : palette.mainOrange)};
  background-color: ${palette.white};
  border: 1px solid
    ${(props) => (props.color ? props.color : palette.mainOrange)};
  &:hover {
    background-color: ${(props) =>
      props.color ? props.color : palette.mainOrangeHover};
    color: ${palette.white};
  }
  margin-left: 8px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  padding: 6px 12px;
  border-radius: 12px;
`;
const MultipleImageUploadInput = styled.input`
  display: none;
`;
const LinkButton = styled.div`
  color: ${(props) => (props.color ? props.color : palette.mainOrange)};
  background-color: ${palette.white};
  border: 1px solid
    ${(props) => (props.color ? props.color : palette.mainOrange)};
  &:hover {
    background-color: ${(props) =>
      props.color ? props.color : palette.mainOrangeHover};
    color: ${palette.white};
  }
  margin-left: 8px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  padding: 6px 12px;
  border-radius: 12px;
`;
//// Panorama Container
const PanoramaContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const GridWrapper = styled.div`
  background-image: repeating-linear-gradient(#ccc 0 1px, transparent 1px 100%),
    repeating-linear-gradient(90deg, #ccc 0 1px, transparent 1px 100%);
  background-size: ${(props) =>
    props.showSubPano ? '80px 80px' : '50px 50px'};
  margin: 0;
  padding: ${(props) =>
    props.showSubPano ? '20px 20px 21px 21px' : '25px 25px 26px 26px'};
  // overflow-y: scroll;
  // overflow-x: auto;
  display: flex;
`;
const GridRow = styled.div`
  width: ${(props) => (props.showSubPano ? '40px' : '50px')};
`;
const GridCellInteger = styled.label`
  all: unset;
  height: 38px;
  width: 38px;
  margin: 5px;
  cursor: pointer;
  font-size: 10px;
  color: #777777;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  border: ${(props) =>
    props.uploadedImg === '' ? '1px solid transparent' : '1px solid black'};
  border-radius: 30px;
  background: none;
  background-image: url(${(props) =>
    props.uploadedImg === '' ? `none` : `${props.uploadedImg}`});
  background-size: cover;
  :hover {
    background-image: none;
    border: 1px solid black;
    color: #111111;
    background-color: transparent;
  }
`;
const GridCell = styled.label`
  all: unset;
  height: 38px;
  width: 38px;
  cursor: pointer;
  font-size: 10px;
  color: ${(props) => (props.uploadedImg === '' ? '#111111' : 'transparent')};
  text-weight: 800;
  display: inline-block;
  text-align: center;
  line-height: 38px;
  border: ${(props) =>
    props.uploadedImg === '' ? '1px solid transparent' : '1px solid black'};
  border-radius: 30px;
  background-color: ${(props) => (props.uploadedImg ? '#111111' : 'none')};
  background-image: url(${(props) =>
    props.uploadedImg === '' ? `none` : `${props.uploadedImg}`});
  background-size: cover;
  :hover {
    background-image: none;
    border: 1px solid black;
    color: #111111;
    background-color: transparent;
  }
`;
const GridFloatCell = styled.div`
  all: unset;
  height: 30px;
  width: 30px;
  margin: 4px;
  cursor: pointer;
  font-size: 10px;
  text-weight: 800;
  color: ${(props) => (props.exist ? '#111111' : 'transparent')};
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  line-height: 30px;
  background: none;
  background-size: cover;
  border-radius: 30px;
  border: ${(props) => (props.exist ? '1px solid' : '1px solid transparent')};
  background-image: ${(props) =>
    props.uploadedImg === '' || props.uploadedImg == null
      ? props.exist
        ? props.disabled_icon
          ? `url(${icon_disabled})`
          : 'none'
        : `url(${icon_dot})`
      : `url(${props.uploadedImg})`};
  :hover {
    color: #111111;
    background-image: none;
  }
`;
const GridCellImageInput = styled.input`
  display: none;
`;

//// Setting Panel
const EditWrapper = styled.div`
  height: 100%;
  margin: 10px 0 0 16px;
  letter-spacing: 0px;
  opacity: 1;
`;
const EditTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
`;
const EditDataWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
const RowWrapper = styled.div`
  width: 200px;
`;
const ElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;
const DetailTitle = styled.div`
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0px;
  color: ${palette.fontDefault};
`;
const DetailSubTitle = styled.div`
  margin-top: 12px;
  text-align: left;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0px;
  color: ${palette.fontDefault};
`;
const DetailCloseButton = styled.div`
  cursor: pointer;
  font-size: 16px;
  color: ${palette.fontWhite};
  background-color: ${palette.grey_lighter};
  &:hover {
    background-color: ${palette.mainBlueLight};
  }
  padding: 0 12px;
  border-radius: 20px;
`;
const DetailSection = styled.div`
  width: 100%;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const DetailDescription = styled.div`
  width: 100%;
  display: flex;
  font-size: 11px;
  color: ${palette.fontDefault};
  line-height: 1.4;
  margin-top: 4px;
`;
const DetailLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${palette.fontBlack};
`;
const DetailValue = styled.div`
  text-align: right;
  font-size: 14px;
  color: ${palette.fontBlack};
`;
const DetailTextareaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 8px;
  width: 40px;
`;
const DetailTextareaDescConnect = styled.div``;
const DetailTextarea = styled.textarea`
  all: unset;
  text-align: center;
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : '20px')};
  border-bottom: 1px solid #cccccc;
  letter-spacing: 0px;
  opacity: 1;
  margin: 4px 0;
  font-size: ${(props) => (props.font_size ? props.font_size : '14px')};
  color: ${(props) =>
    props.font_color ? props.font_color : palette.fontBlack};
  padding-top: 4px;
`;

const MediaDescTextarea = styled.textarea`
  all: unset;
  text-align: center;
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : '300px')};
  border: 1px solid #cccccc;
  letter-spacing: 0px;
  opacity: 1;
  margin: 4px 0;
  font-size: ${(props) => (props.font_size ? props.font_size : '14px')};
  color: ${(props) =>
    props.font_color ? props.font_color : palette.fontBlack};
  padding-top: 4px;
`;
////// Relation
const AttachWrapper = styled.div`
  margin-bottom: 20px;
`;
const RelationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 150px;
  overflow: scroll;
  border: solid 1px ${palette.borderLightGrey};
  padding: 8px;
`;
const RelationItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  justify-content: space-between;
`;
const RelationItem = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 700;
  margin-left: 0px;
  line-height: 24px;
`;
const DetachButton = styled.div`
  height: 24px;
  width: 24px;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '18px')};
  font-weight: 800;
  line-height: 24px;
  cursor: pointer;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : palette.buttonDelete};
  color: ${palette.white};
  border-radius: 20px;
  text-align: center;
  &:hover {
    background-color: ${palette.buttonDeleteHover};
  }
`;
const EditButtonWrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.direction ? props.direction : 'column')};
  margin-top: 10px;
  align-items: end;
  justify-content: center;
  width: 100%;
`;
const HelpButton = styled.div`
  color: ${palette.mainBlue};
  font-size: 16px;
  font-weight: 800;
  border: 2px solid;
  border-radius: 10px;
  width: 20px;
  text-align: center;
  position: absolute;
  top: 0px;
  right: 0px;
`;
const HelpWrapper = styled.div`
  position: absolute;
  padding: 10px;
  background-color: ${palette.background};
  width: 200px;
  opacity: 1;
  right: 16px;
  top: 16px;
  z-index: 10;
  border-radius: 8px;
  box-shadow: 0 0 40px 0 #777777;
`;
const HelpDescription = styled.div`
  width: 100%;
  display: flex;
  font-size: 10px;
  color: ${palette.fontDefault};
  line-height: 1.4;
  margin-top: 4px;
`;
const Button = styled.div`
  background-color: ${palette.mainBlue};
  &:hover {
    background-color: ${palette.mainBlueHover};
  }
  width: 50px;
  padding: 3px 8px;
  cursor: pointer;
  color: white;
  margin-left: 8px;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  border-radius: 8px;
  white-space: nowrap;
`;

//// COMMON
const Dropdown = styled.select`
  font-size: 12px;
  margin-top: ${(props) => (props.margin_top ? props.margin_top : '0px')};
  margin-left: ${(props) => (props.margin_left ? props.margin_left : '0px')};
  margin-right: ${(props) => (props.margin_right ? props.margin_right : '0px')};
  padding: 3px;
  height: 24px;
  border-radius: 6px;
  border: 1px solid ${palette.mainBlue};
  color: ${palette.fontDefault};
  background-color: ${palette.background};
`;
const CustomContextMenu = styled.ul`
  font-size: 14px;
  background-color: #fff;
  border-radius: 2px;
  width: 150px;
  height: auto;
  margin: 0;
  position: absolute;
  list-style: none;
  box-shadow: 0 0 20px 0 #ccc;
  opacity: 1;
  transition: opacity 0.5s linear;
`;
const CustomContextMenuItem = styled.li`
  padding: 10px 10px;
  :hover {
    background-color: rgb(122, 122, 122, 0.1);
  }
  cursor: pointer;
`;
const ImageUploadInput = styled.input`
  display: none;
  cursor: pointer;
`;

const ImageWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
`;

const ImageContents = styled.div`
  margin-right: 10px;
  margin-bottom: 10px;
  width: 85px;
  height: 85px;
  background: #ffffff 0% 0% no-repeat padding-box;
  background-image: url(${(props) =>
    props.uploadedImg === '' ? `${props.initialImg}` : `${props.uploadedImg}`});
  background-size: contain;
  background-position: center;
  border: 1px solid rgb(122, 122, 122, 0.3);
  border-radius: 10px;
  :hover {
    opacity: 0.5;
  }
  z-index: 0;
  cursor: pointer;
`;

const ImageUploadButton = styled.label`
  box-sizing: border-box;
  all: unset;
  margin-right: 10px;
  width: 85px;
  height: 85px;
  background: #ffffff 0% 0% no-repeat padding-box;
  background-image: url(${(props) => (props.icon ? props.icon : icon_plus)});
  background-position: center;
  border: 1px solid rgb(122, 122, 122, 0.3);
  border-radius: 10px;
  :hover {
    background-color: rgb(122, 122, 122, 0.1);
  }
  cursor: pointer;
`;

const ImageDeleteButton = styled.div`
  box-sizing: border-box;
  all: unset;
  margin-right: 10px;
  width: 30px;
  height: 30px;
  background: url(${icon_trashbin}) 0% 0% no-repeat padding-box;
  background-size: contain;
  background-position: center;
  border: 1px solid rgb(122, 122, 122, 0.3);
  border-radius: 8px;
  color: white;
  :hover {
    background-color: ${palette.red};
  }
  cursor: pointer;
`;

const VideoContents = styled.video`
  margin-right: 10px;
  margin-bottom: 10px;
  width: 200px;
  height: 200px;
  background: #ffffff 0% 0% no-repeat padding-box;
  background-size: contain;
  background-position: center;
  border: 1px solid rgb(122, 122, 122, 0.3);
  border-radius: 10px;
  :hover {
    opacity: 0.5;
  }
  z-index: 0;
`;

const VideoUploadButton = styled.label`
  box-sizing: border-box;
  all: unset;
  margin-right: 10px;
  width: 85px;
  height: 85px;
  background: #ffffff 0% 0% no-repeat padding-box;
  background-image: url(${(props) => (props.icon ? props.icon : icon_plus)});
  background-position: center;
  border: 1px solid rgb(122, 122, 122, 0.3);
  border-radius: 10px;
  :hover {
    background-color: rgb(122, 122, 122, 0.1);
  }
`;

const VideoDeleteButton = styled.div`
  box-sizing: border-box;
  all: unset;
  margin-right: 10px;
  width: 30px;
  height: 30px;
  background: url(${icon_trashbin}) 0% 0% no-repeat padding-box;
  background-size: contain;
  background-position: center;
  border: 1px solid rgb(122, 122, 122, 0.3);
  border-radius: 8px;
  color: white;
  :hover {
    background-color: ${palette.red};
  }
`;

const VideoUploadInput = styled.input`
  display: none;
`;
const ContentsLoadingWrapper = styled.div`
  display: flex;
  margin-left: 12px;
  align-items: center;
  font-family: Pretendard-r;
  font-size: 10px;
  color: ${palette.fontBlue};
  font-weight: 400;
  gap: 8px;
`;

export default PanoramaModal;
