import axios from 'axios';
import { apiPaths } from 'modules/defines/paths';

const api = axios.create({
  baseURL: process.env.REACT_APP_LOCAL
    ? apiPaths.devBaseUrl
    : apiPaths.awsBaseUrl,
  xsrfHeaderName: 'X-CSRFToken',
  withCredentials: true,
});

export const spaceAPI = {
  spaceAll: () => api.get(apiPaths.getSpaceAll),
  spaceDetail: (id) => api.get(apiPaths.getSpaceSingle + id),
  spaceKeyword: (id) => api.get(apiPaths.getSpaceKeyword + id),
  addKeyword: (data) => api.post(apiPaths.addSpaceKeyword, data),
  removeKeyword: (data) => api.post(apiPaths.removeSpaceKeyword, data),
  updateVibeKeyword: (data) => api.post(apiPaths.updateVibeSpaceKeyword, data),
  spaceFilter: (id) => api.get(apiPaths.getSpaceFilter + id),
  addFilter: (data) => api.post(apiPaths.addSpaceFilter, data),
  removeFilter: (data) => api.post(apiPaths.removeSpaceFilter, data),
  updateFilter: (data) => api.post(apiPaths.updateSpaceFilter, data),
  searchAddressByPlace: (data) => api.post(apiPaths.searchAddressByName, data),
  convertAddressWithNaver: (data) =>
    api.post(apiPaths.convertAddressWithNaver, data),
  convertAddressWithGoogle: (data) =>
    api.post(apiPaths.convertAddressWithGoogle, data),
  spaceRegister: (data) => api.post(apiPaths.registerSpace, data),
  spaceEdit: (data) => api.post(apiPaths.editSpace, data),
  spaceEditPaid: (data) => api.post(apiPaths.editPaidSpace, data),
  editSpacePublishAt: (data) => api.post(apiPaths.editSpacePublishDate, data),
  switchPublish: (data) => api.post(apiPaths.switchSpacePublish, data),
  spaceDelete: (data) => api.post(apiPaths.deleteSpace, data),
  getImage: (id) => api.get(apiPaths.getSpaceImage + id),
  uploadImage: (data, headers) =>
    api.post(apiPaths.uploadSpaceImage, data, { headers }),
  uploadImageForward: (data) =>
    api.post(apiPaths.uploadSpaceImageForward, data),
  deleteImage: (data) => api.post(apiPaths.deleteSpaceImage, data),
  deleteAllImage: (data) => api.post(apiPaths.deleteSpaceAllImage, data),
  getVideo: (id) => api.get(apiPaths.getSpaceVideo + id),
  uploadVideo: (data, headers) =>
    api.post(apiPaths.uploadSpaceVideo, data, { headers }),
  deleteAllVideo: (data) => api.post(apiPaths.deleteSpaceAllVideo, data),
  getPanorama: (id) => api.get(apiPaths.getPanorama + id),
  registerPanorama: (data) => api.post(apiPaths.registerPanorama, data),
  uploadPanoramaImage: (data, headers) =>
    api.post(apiPaths.uploadPanoramaImage, data, { headers }),
  deletePanoramaImage: (id) => api.delete(apiPaths.deletePanoramaImage + id),
  deletePanoramaImageAll: (id) =>
    api.delete(apiPaths.deletePanoramaImageAll + id),
  refreshPanoramaImage: (id) => api.delete(apiPaths.refreshPanoramaImage + id),
  formatPanoramaMode: (data) => api.post(apiPaths.formatPanoramaMode, data),
  editPanoramaInfo: (data) => api.post(apiPaths.editPanorama, data),
  editPanoramaMediaInfo: (data) =>
    api.post(apiPaths.editPanoramaMediaInfo, data),
  uploadPanonamaMediaImage: (data, headers) =>
    api.post(apiPaths.uploadPanonamaMediaImage, data, { headers }),
  deletePanoramaMediaImage: (data) =>
    api.post(apiPaths.deletePanoramaMediaImage, data),
  uploadPanonamaMediaVideo: (data, headers) =>
    api.post(apiPaths.uploadPanonamaMediaVideo, data, { headers }),
  deletePanoramaMediaVideo: (data) =>
    api.post(apiPaths.deletePanoramaMediaVideo, data),
  editPanoramaRoomNameAll: (data) =>
    api.post(apiPaths.editPanoramaRoomNameAll, data),
  loadPanoramaFromDefaultMode: (data) =>
    api.post(apiPaths.loadPanoramaFromDefaultMode, data),
  changePanoramaMode: (data) => api.post(apiPaths.changePanoramaMode, data),
  attachPanoramaRelationBlock: (data) =>
    api.post(apiPaths.attachPanoramaRelationBlock, data),
  attachPanoramaRelationConnect: (data) =>
    api.post(apiPaths.attachPanoramaRelationConnect, data),
  attachPanoramaRelationExternal: (data) =>
    api.post(apiPaths.attachPanoramaRelationExternal, data),
  attachPanoramaRelationMedia: (data) =>
    api.post(apiPaths.attachPanoramaRelationMedia, data),
  detachPanoramaRelation: (data) =>
    api.post(apiPaths.detachPanoramaRelation, data),
  detachPanoramaRelationExternal: (data) =>
    api.post(apiPaths.detachPanoramaRelationExternal, data),

  extendExpired: (data) => api.post(apiPaths.extendExpired, data),
};
