import { pagePaths } from './paths';
export const panorama_values = {
  floor: [
    -8, -7, -6, -5, -4, -3, -2, -1, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,
    14, 15, 16, 17, 18, 19, 20, 99,
  ],
  mode: ['default', 'medium', 'small'],
  type: ['normal', 'image', 'video'],
  category: [
    'default',
    'entrance',
    'exterior',
    'elevator',
    'toilet',
    'toilet_m',
    'toilet_w',
    'cigarette',
    'exit',
    'room',
    'meetingroom',
    'escalator',
  ],
  subcategory: [
    'default',
    'entrance',
    'toilet',
    'toilet_m',
    'toilet_w',
    'exit',
    'parking',
    'parking_disabled',
    'elevator',
    'escalator',
    'reservation',
  ],
};

export const time_values = {
  open: ['매일', '월', '화', '수', '목', '금', '토', '일'],
  closed: ['없음', '월', '화', '수', '목', '금', '토', '일'],
};

export const admin_values = {
  role: ['super-admin', 'admin', 'curator', 'editor', 'marketer'],
};
export const routes_permission_values = {
  'super-admin': [
    pagePaths.user.adminList,
    pagePaths.user.list,
    pagePaths.user.detail,
    pagePaths.user.dashboardData,
    pagePaths.user.dashboardApp,
    pagePaths.curation.list,
    pagePaths.curation.detail,
    pagePaths.space.list,
    pagePaths.space.detail,
    pagePaths.keyword.filterList,
    pagePaths.keyword.list,
    pagePaths.keyword.customDetail,
    pagePaths.keyword.customList,
    pagePaths.notification.list,
    pagePaths.notification.create,
    pagePaths.notification.detail,
    pagePaths.event.list,
    pagePaths.event.create,
    pagePaths.event.detail,
    pagePaths.document.list,
    pagePaths.document.create,
    pagePaths.document.detail,
    pagePaths.feedback.list,
    pagePaths.feedback.detail,
    pagePaths.push.list,
    pagePaths.push.detail,
    pagePaths.managing.link,
    pagePaths.managing.version,
    pagePaths.etc.threejs,
    pagePaths.store.managerList,
    pagePaths.store.managerDetail,
    pagePaths.store.productList,
    pagePaths.store.productDetail,
    pagePaths.store.editRequestList,
    pagePaths.store.editRequestDetail,
    pagePaths.store.orderList,
    pagePaths.faviewRequestList,
  ],
  admin: [
    pagePaths.user.list,
    pagePaths.user.detail,
    pagePaths.user.dashboardData,
    pagePaths.user.dashboardApp,
    pagePaths.curation.list,
    pagePaths.curation.detail,
    pagePaths.space.list,
    pagePaths.space.detail,
    pagePaths.keyword.list,
    pagePaths.notification.list,
    pagePaths.event.list,
    pagePaths.document.list,
    pagePaths.feedback.list,
    pagePaths.feedback.detail,
    pagePaths.push.list,
    pagePaths.managing.link,
    pagePaths.etc.threejs,
    pagePaths.store.managerList,
    pagePaths.store.managerDetail,
    pagePaths.store.productList,
    pagePaths.store.productDetail,
    pagePaths.store.editRequestList,
    pagePaths.store.editRequestDetail,
    pagePaths.store.orderList,
    pagePaths.faviewRequestList,
  ],
  curator: [
    pagePaths.user.dashboardData,
    pagePaths.curation.list,
    pagePaths.curation.detail,
    pagePaths.space.list,
    pagePaths.space.detail,
    pagePaths.keyword.list,
  ],
  editor: [
    pagePaths.user.dashboardData,
    pagePaths.space.list,
    pagePaths.space.detail,
    pagePaths.keyword.list,
  ],
  marketer: [
    pagePaths.user.dashboardData,
    pagePaths.user.dashboardApp,
    pagePaths.curation.list,
    pagePaths.curation.detail,
    pagePaths.space.list,
    pagePaths.space.detail,
    pagePaths.keyword.list,
    pagePaths.store.orderList,
    pagePaths.user.list,
    pagePaths.user.detail,
    pagePaths.push.list,
    pagePaths.push.detail,
  ],
};

export const filter_values = {
  parking: ['주차 가능', '무료 주차', '유료 주차', '발렛파킹'],
  pet: ['반려동물 동반', '케이지 동반', '야외 동반'],
  kids: ['노키즈존', '부분키즈존'],
};

export const product_values = {
  status: [
    '0.작성 중',
    '1.승인 대기',
    '2.승인 취소',
    '3.판매 대기',
    '4.판매 중',
    '5.판매 중지',
    '6.판매 종료',
  ],
};

export const request_values = {
  status: [
    '0.배포 완료',
    '1.승인 대기',
    '2.촬영 대기',
    '3.제작 대기',
    '4.취소',
    '5.완료',
  ],
};
