import { useContext } from 'react';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { UserAgent } from 'utils/userAgent';
import { UserContext } from 'modules/api/user';
import { palette } from 'modules/defines/styles';
import LogoImage from 'assets/icon/admin-logo.png';
import { spaceAPI } from 'modules/api';
import { pagePaths } from 'modules/defines/paths';
import CurationIcon from 'assets/svg/Curation';
import StoreIcon from 'assets/svg/Store';
import UserIcon from 'assets/svg/Person';
import AdminIcon from 'assets/svg/Admin';
import BoardIcon from 'assets/svg/Board';
import LinkIcon from 'assets/svg/Link';
import FeedbackIcon from 'assets/svg/Feedback';
import AlarmIcon from 'assets/svg/Alarm';
import DashBoardIcon from 'assets/svg/Dashboard';
import HashtagIcon from 'assets/svg/Hashtag';

const HeaderComponent = ({
  userRole,
  toggleShowMenu,
  setShowMenu,
  sendFeedback,
}) => {
  const [userContext] = useContext(UserContext);
  const { os } = UserAgent;

  const havePermission = userContext.user.role === 'super-admin';
  const onClickDBJsonUpdate = async () => {
    await spaceAPI.updateSpaceJson().then((result) => {
      if (result.complete) {
        alert('데이터베이스 json 파일 갱신 완료.');
      } else {
        alert('데이터베이스 json 파일 갱신 실패.\n개발자에게 문의 바람');
      }
    });
  };

  return (
    <Header>
      <HeaderWrapper>
        <Link to="/">
          <ImageLogo />
        </Link>
        <BackBtn onClick={(e) => toggleShowMenu(e)}>&lt;</BackBtn>
      </HeaderWrapper>

      <MenuWrapper>
        <GroupTitle>DATA MANAGE</GroupTitle>

        <MenuItemWrapper to={pagePaths.user.dashboardData}>
          <DashBoardIcon />
          <MenuTitle>데이터 대시보드</MenuTitle>
        </MenuItemWrapper>

        {userRole === 'editor' ? null : (
          <>
            <MenuItemWrapper to={pagePaths.curation.list}>
              <CurationIcon />
              <MenuTitle>큐레이션 관리</MenuTitle>
            </MenuItemWrapper>
          </>
        )}

        <MenuItemWrapper to={pagePaths.space.list}>
          <StoreIcon />
          <MenuTitle>공간 관리</MenuTitle>
        </MenuItemWrapper>

        {userRole === 'admin' || userRole === 'super-admin' ? (
          <>
            {/* <MenuItemWrapper
              current={(pathName === 'filter').toString()}
              to={`/filter`}
              
            >
              <FilterIcon />
              <MenuTitle>필터 관리</MenuTitle>
            </MenuItemWrapper>
            
            <MenuItemWrapper
              current={(pathName === 'image').toString()}
              to={`/image`}
              
            >
              <ImageIcon />
              <MenuTitle>이미지 관리</MenuTitle>
            </MenuItemWrapper> */}
            <MenuItemWrapper to={pagePaths.keyword.list}>
              <HashtagIcon />
              <MenuTitle>키워드 관리</MenuTitle>
            </MenuItemWrapper>
            <MenuItemWrapper to={pagePaths.keyword.customList}>
              <HashtagIcon />
              <MenuTitle>커스텀 키워드</MenuTitle>
            </MenuItemWrapper>
            <MenuItemWrapper to={pagePaths.feedback.list}>
              <FeedbackIcon />
              <MenuTitle>피드백 관리</MenuTitle>
            </MenuItemWrapper>
            <MenuItemWrapper to={pagePaths.push.list}>
              <AlarmIcon />
              <MenuTitle>푸시알림 관리</MenuTitle>
            </MenuItemWrapper>
          </>
        ) : null}
        {userRole === 'marketer' && (
          <>
            <MenuItemWrapper to={pagePaths.keyword.list}>
              <HashtagIcon />
              <MenuTitle>키워드 관리</MenuTitle>
            </MenuItemWrapper>
            <MenuItemWrapper to={pagePaths.push.list}>
              <AlarmIcon />
              <MenuTitle>푸시알림 관리</MenuTitle>
            </MenuItemWrapper>
          </>
        )}
      </MenuWrapper>

      {userRole === 'admin' || userRole === 'super-admin' ? (
        <>
          <MenuWrapper>
            <GroupTitle>USER MANAGE</GroupTitle>
            {userRole === 'super-admin' && (
              <MenuItemWrapper to={pagePaths.user.adminList}>
                <AdminIcon />
                <MenuTitle>관리자 관리</MenuTitle>
              </MenuItemWrapper>
            )}
            <MenuItemWrapper to={pagePaths.user.list}>
              <UserIcon />
              <MenuTitle>유저 관리</MenuTitle>
            </MenuItemWrapper>
          </MenuWrapper>
          <MenuWrapper>
            <GroupTitle>FAVIEW MANAGE</GroupTitle>
            <MenuItemWrapper to={pagePaths.faviewRequestList}>
              <StoreIcon />
              <MenuTitle>제작요청 관리</MenuTitle>
            </MenuItemWrapper>
          </MenuWrapper>
          <MenuWrapper>
            <GroupTitle>STORE MANAGE</GroupTitle>
            <MenuItemWrapper to={pagePaths.store.managerList}>
              <UserIcon />
              <MenuTitle>매니저 관리</MenuTitle>
            </MenuItemWrapper>
            <MenuItemWrapper to={pagePaths.store.productList}>
              <StoreIcon />
              <MenuTitle>상품승인 관리</MenuTitle>
            </MenuItemWrapper>
            <MenuItemWrapper to={pagePaths.store.editRequestList}>
              <FeedbackIcon />
              <MenuTitle>공간정보 수정요청</MenuTitle>
            </MenuItemWrapper>
            <MenuItemWrapper to={pagePaths.store.orderList}>
              <StoreIcon />
              <MenuTitle>구매목록</MenuTitle>
            </MenuItemWrapper>
          </MenuWrapper>

          <MenuWrapper>
            <GroupTitle>APP MANAGE</GroupTitle>
            <MenuItemWrapper to={pagePaths.user.dashboardApp}>
              <DashBoardIcon />
              <MenuTitle>앱 대시보드</MenuTitle>
            </MenuItemWrapper>
            <MenuItemWrapper to={pagePaths.notification.list}>
              <BoardIcon />
              <MenuTitle>공지사항</MenuTitle>
            </MenuItemWrapper>
            <MenuItemWrapper to={pagePaths.document.list}>
              <BoardIcon />
              <MenuTitle>이용약관</MenuTitle>
            </MenuItemWrapper>
            <MenuItemWrapper to={pagePaths.event.list}>
              <BoardIcon />
              <MenuTitle>이벤트</MenuTitle>
            </MenuItemWrapper>
            <MenuItemWrapper to={pagePaths.managing.link}>
              <LinkIcon />
              <MenuTitle>링크 관리</MenuTitle>
            </MenuItemWrapper>
            <MenuItemWrapper to={pagePaths.managing.version}>
              <LinkIcon />
              <MenuTitle>앱버전</MenuTitle>
            </MenuItemWrapper>
            <MenuItemWrapper to={pagePaths.etc.threejs}>
              <LinkIcon />
              <MenuTitle>TEST</MenuTitle>
            </MenuItemWrapper>
          </MenuWrapper>
        </>
      ) : null}
      {userRole === 'marketer' && (
        <>
          <MenuWrapper>
            <GroupTitle>USER MANAGE</GroupTitle>
            <MenuItemWrapper to={pagePaths.user.list}>
              <UserIcon />
              <MenuTitle>유저 관리</MenuTitle>
            </MenuItemWrapper>
          </MenuWrapper>
          <MenuWrapper>
            <GroupTitle>STORE MANAGE</GroupTitle>
            <MenuItemWrapper to={pagePaths.store.orderList}>
              <StoreIcon />
              <MenuTitle>구매목록</MenuTitle>
            </MenuItemWrapper>
          </MenuWrapper>
        </>
      )}
      <TextButtonWrapper>
        {havePermission && (
          <TextButton onClick={onClickDBJsonUpdate}>DB JSON 갱신</TextButton>
        )}
        <TextButton onClick={sendFeedback}>개발자 요청</TextButton>
      </TextButtonWrapper>
    </Header>
  );
};

const Header = styled.header`
  display: flex;
  flex-direction: column;
  top: 0px;
  left: 0px;
  height: 100vh;
  background-color: ${palette.white};
  border-right: 0.01rem solid rgb(0, 0, 0, 0.1);
  opacity: 1;
  padding: 10px 0 0 10px;
  width: 220px;
  @media screen and (max-width: 400px) {
    padding: 10px 15px 0px 5px;
  }
  overflow-y: scroll;
  padding-bottom: 20px;
  .isMobile & {
    width: 180px;
    position: absolute;
    top: 4px;
    left: 8px;
    z-index: 99;
    border-radius: 32px;
    border: 0.1rem solid rgb(0, 0, 0, 0.1);
    padding: 12px 0 20px 10px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  }
  @media screen and (max-width: 1000px) {
    width: 180px;
    position: absolute;
    top: 4px;
    left: 8px;
    z-index: 99;
    border-radius: 32px;
    border: 0.1rem solid rgb(0, 0, 0, 0.1);
    padding: 12px 0 20px 10px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  }
`;
const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ImageLogo = styled.img.attrs({
  src: `${LogoImage}`,
})`
  height: 50px;
  .isMobile & {
    height: 32px;
  }
`;

const BackBtn = styled.div`
  width: 28px;
  height: 28px;
  border: 1px solid ${palette.borderLightGrey};
  border-radius: 20px;
  background-color: ${palette.background};
  align-items: center;
  justify-content: center;
  font-family: Pretendard-eb;
  font-size: 18px;
  margin-right: 12px;
  color: ${palette.grey_lighter};
  cursor: pointer;
  &:hover {
    background-color: ${palette.mainBlue};
  }
  display: none;

  @media screen and (max-width: 1000px) {
    display: flex;
  }
`;

const MenuWrapper = styled.div`
  margin-top: 30px;
  margin-left: 10px;
  .isMobile & {
    width: 100%;
    margin-top: 10px;
    margin-left: 4px;
  }
`;

const MenuItemWrapper = styled(Link)`
  display: flex;
  margin-left: 10px;
  flex-direction: row;
  align-items: center;
  height: 40px;
  .isMobile & {
    height: 36px;
  }
`;

const GroupTitle = styled.div`
  font-size: 11px;
  line-height: 1.09;
  color: ${palette.fontGray};
`;

const MenuTitle = styled.div`
  font-size: 14px;
  line-height: 1;
  color: ${palette.fontDefault};
  margin-left: 8px;
  @media screen and (max-width: 400px) {
    font-size: 12px;
  }
  &:hover {
    color: ${palette.fontBlue};
  }
`;

const TextButtonWrapper = styled.div`
  margin-left: 20px;
  margin-top: 24px;
`;

const TextButton = styled.div`
  font-size: 13px;
  font-family: Pretendard-r;
  color: ${palette.fontDefault};
  &:hover {
    color: ${palette.mainOrange};
  }
  cursor: pointer;
  margin-bottom: 20px;
`;

export default withRouter(HeaderComponent);
