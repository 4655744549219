import { useState, useEffect, useMemo, useContext } from 'react';
import { GlobalContext } from 'modules/context/GlobalContext';
import styled from 'styled-components';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { UserAgent } from 'utils/userAgent';
import { TailSpin } from 'react-loader-spinner';
import { pagePaths } from 'modules/defines/paths';
import { UserContext } from 'modules/api/user';

import {
  Container,
  LayoutWrapper,
  LoadingText,
  ContainerHeaderWrapper,
  ContainerHeaderWrapperWrapper,
  ContainerHeader,
  ContainerHeaderSubtext,
  PageContainer,
  PageWrapper,
  TableToolBarContainer,
  TableToolBarWrapper,
  SearchWrapper,
  SearchTitle,
  SearchInput,
} from 'components/common/DataTableStyle';
import DefaultTable from 'components/table/DefaultTable';
import { palette } from 'modules/defines/styles';

const UserListContainer = () => {
  const { os } = UserAgent;
  const history = useHistory();
  const globalState = useContext(GlobalContext);
  const [userContext] = useContext(UserContext);

  const [userData, setUserData] = useState(null);
  const [userDataCount, setUserDataCount] = useState(0);
  const [searchValue, setSearchValue] = useState('');

  // Pagination
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const currentPage = queryParams.get('page');
  const [numElementInPage, setNumElementInPage] = useState(
    os.isAndroid || os.ios ? 5 : 10
  );
  const [pageTotalNum, setPageTotalNum] = useState(0);
  const [pageList, setPageList] = useState([]);

  // 권한 확인 후 차단
  if (!userContext.user.role.match('super-admin|admin|marketer')) {
    history.push(pagePaths.space.list);
  }

  useEffect(() => {
    setUserData(globalState.user.filter((user) => user.role === 'registered'));
    setUserDataCount(
      globalState.user.filter((user) => user.role === 'registered').length
    );
  }, [globalState.user]);

  // (Data Initialize) Table COLUMN
  const tableColumns = useMemo(() => {
    const columnData = [
      {
        accessor: 'img',
        Header: 'Image',
        Cell: ({ row }) => <CellImage url={row.values.img} />,
        Filter: false,
        width: 70,
        Sort: false,
        align: 'center',
      },
      {
        accessor: 'id',
        Header: 'ID',
        Filter: false,
        Resize: false,
        width: 50,
      },
      {
        accessor: 'uid',
        Header: 'UID',
        Filter: false,
        width: 100,
        Sort: false,
        Resize: false,
      },
      {
        accessor: 'phone',
        Header: 'Phone',
        Cell: ({ row }) => (
          <Link to={pagePaths.user.detail.replace(':id', row.values.id)}>
            {row.values.phone}
          </Link>
        ),
        Filter: false,
        Sort: false,
        Resize: false,
        width: 150,
      },
      {
        accessor: 'name',
        Header: 'Name',
        Cell: ({ row }) => (
          <Link to={pagePaths.user.detail.replace(':id', row.values.id)}>
            {row.values.name}
          </Link>
        ),
        Filter: false,
        Resize: false,
        width: 120,
      },
      {
        accessor: 'email',
        Header: 'Email',
        Cell: ({ row }) => (
          <Link to={pagePaths.user.detail.replace(':id', row.values.id)}>
            {row.values.email}
          </Link>
        ),
        Filter: false,
        Resize: false,
        width: 200,
      },
      {
        accessor: 'gender',
        Header: 'gender',
        Filter: false,
        width: 60,
      },
      { accessor: 'birth', Header: 'birth', Filter: false },
      // { accessor: 'address', Header: 'address', Filter: false },
      {
        accessor: 'createdAt',
        Cell: ({ row }) => row.values.createdAt.slice(0, 10),
        Header: '가입일자',
        Filter: false,
      },
    ];
    return columnData;
  }, [userData, currentPage]);

  // (Data Initialize) TABLE DATA
  const tableData = useMemo(() => {
    if (!userData) {
      return [];
    }
    const userArr = userData
      .filter((item) => {
        for (let value of Object.values(item)) {
          if (value && String(value).includes(searchValue)) return true;
        }
        return false;
      })
      .map((noti) => {
        return {
          ...noti,
        };
      });
    return userArr;
  }, [userData, searchValue, currentPage, numElementInPage]);

  // (Pagination) 펼쳐보기에 따른 보기 설정
  useEffect(() => {
    if (currentPage === undefined || currentPage === null)
      history.push(`${pagePaths.user.list}?page=1`);
  }, [currentPage]);

  // (Pagination) 데이터 개수와 총 페이지 수 계산 및 적용
  useEffect(() => {
    if (!tableData) {
      return 0;
    } else if (tableData === null) {
      return 0;
    } else {
      setUserDataCount(tableData?.length);
    }
    setPageTotalNum(Math.ceil(tableData?.length / numElementInPage));
  }, [tableData?.length, numElementInPage]);

  // (Pagination) Intialize
  useEffect(() => {
    setPageList(getRange(currentPage, pageTotalNum));
  }, [pageTotalNum, currentPage]);

  // (Pagination) 띄워줄 페이지 넘버 리스트들을 불러오는 함수
  const getRange = (currentPage, pageTotalNum) => {
    var pageOrderNum = Math.floor((currentPage - 1) / 10);
    var array = [];
    if (pageOrderNum >= Math.floor((pageTotalNum - 1) / 10)) {
      for (var i = pageOrderNum * 10 + 1; i <= pageTotalNum; i++) {
        array.push(i);
      }
    } else {
      for (var i = pageOrderNum * 10 + 1; i < pageOrderNum * 10 + 11; i++) {
        array.push(i);
      }
    }
    // (Exception) array가 줄어들어서 current가 array에 벗어날 경우
    if (!array.includes(Number(currentPage))) {
      history.push(`${pagePaths.user.list}?page=1`);
    }
    return array;
  };

  // (Pagination) page 이동
  const movePageListener = (e, clickedPageNum) => {
    e.preventDefault();
    history.push(`${pagePaths.user.list}?page=${clickedPageNum}`);
  };

  // (Pagination) page 한 번에 이동
  const movePageChunkListener = (e, direction, endward) => {
    e.preventDefault();
    var movePage = 0;
    if (!endward) {
      if (direction === 'forward') {
        movePage = (Math.floor((currentPage - 1) / 10) + 1) * 10 + 1;
      } else if (direction === 'backward') {
        movePage = (Math.floor((currentPage - 1) / 10) - 1) * 10 + 10;
      }
    } else {
      if (direction === 'forward') {
        movePage = pageTotalNum;
      } else if (direction === 'backward') {
        movePage = 1;
      }
    }
    if (movePage > 0 && movePage <= pageTotalNum) {
      history.push(`${pagePaths.user.list}?page=${movePage}`);
    }
  };

  // Pagination Resp
  useEffect(() => {
    const handleResize = () => {
      if (window.innerHeight < 600) {
        setNumElementInPage(4);
      } else if (window.innerHeight < 700) {
        setNumElementInPage(5);
      } else if (window.innerHeight < 750) {
        setNumElementInPage(6);
      } else if (window.innerHeight < 880) {
        setNumElementInPage(7);
      } else if (window.innerHeight < 1000) {
        setNumElementInPage(8);
      } else if (window.innerHeight < 1100) {
        setNumElementInPage(10);
      } else if (window.innerHeight < 1200) {
        setNumElementInPage(11);
      } else if (window.innerHeight < 1300) {
        setNumElementInPage(12);
      } else if (window.innerHeight < 1400) {
        setNumElementInPage(13);
      } else if (window.innerHeight < 1600) {
        setNumElementInPage(14);
      } else {
        setNumElementInPage(18);
      }
    };
    // 이벤트 리스너 등록
    window.addEventListener('resize', handleResize);
    // 초기 렌더링에서 한 번 실행
    handleResize();
    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const testClickListener = () => {
    console.log(currentPage);
  };

  return (
    <Container>
      <ContainerHeaderWrapper>
        <ContainerHeaderWrapperWrapper>
          <ContainerHeader onClick={(e) => testClickListener(e)}>
            유저 설정
          </ContainerHeader>
          <ContainerHeaderSubtext>{userDataCount}명</ContainerHeaderSubtext>
        </ContainerHeaderWrapperWrapper>
        <TableToolBarContainer>
          <TableToolBarWrapper></TableToolBarWrapper>
          <TableToolBarWrapper>
            {/* 검색 */}
            <SearchWrapper>
              <SearchTitle>검색 : </SearchTitle>
              <SearchInput
                width="160px"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </SearchWrapper>
          </TableToolBarWrapper>
        </TableToolBarContainer>
      </ContainerHeaderWrapper>

      <LayoutWrapper viewTotal={false} isPaginated={true}>
        {globalState.loadingUser ? (
          <LoadingText>
            Loading...
            <br />
            데이터 로딩 중입니다...
            <TailSpin color={palette.mainBlue} width={50} height={50} />
          </LoadingText>
        ) : (
          tableData &&
          tableColumns && (
            <>
              <DefaultTable
                columns={tableColumns}
                data={tableData}
                numElementInPage={numElementInPage}
                currentPage={currentPage}
              />
              <PageContainer>
                <PageWrapper
                  onClick={(e) => movePageChunkListener(e, 'backward', true)}
                >
                  &lt;&lt;
                </PageWrapper>
                <PageWrapper
                  onClick={(e) => movePageChunkListener(e, 'backward', false)}
                >
                  &lt;
                </PageWrapper>
                {pageList.map((page, idx) => {
                  if (page == currentPage)
                    return (
                      <PageWrapper key={idx + '_page_wrapper_1'} current={true}>
                        {page}
                      </PageWrapper>
                    );
                  else
                    return (
                      <PageWrapper
                        key={idx + '_page_wrapper_2'}
                        onClick={(e) => movePageListener(e, page)}
                        current={false}
                      >
                        {page}
                      </PageWrapper>
                    );
                })}

                <PageWrapper
                  onClick={(e) => movePageChunkListener(e, 'forward', false)}
                >
                  &gt;
                </PageWrapper>

                <PageWrapper
                  onClick={(e) => movePageChunkListener(e, 'forward', true)}
                >
                  &gt;&gt;
                </PageWrapper>
              </PageContainer>
            </>
          )
        )}
      </LayoutWrapper>
    </Container>
  );
};

const CellImage = styled.div`
  width: 60px;
  height: 60px;
  background-image: url(${(props) => props.url});
  background-size: cover;
`;

export default UserListContainer;
